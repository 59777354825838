import { storeAnalyticsEvent, useAnalytics } from "../../analytics";
import { Container, Input } from "../../shared/components";
import { forwardRef } from "react";
import axios from "axios";
import { useState } from "react";
import { getStageConfig } from "../../config/config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useResponsiveStyles } from "../../shared/mobile";

export const HeroSection = forwardRef(({ url }, ref) => {
  const config = getStageConfig();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const isMobile = useResponsiveStyles();

  const { t, i18n } = useTranslation();
  const styles = {
    backgroundColor: "black",
    height: "100vh",
    width: "100%",
    marginBottom: "5%",
    overflowX: "hidden",
    boxSizing: "border-box",
    position: "relative",
    top: 0,
  };

  const noteStyle = {
    margin: 0,
    fontSize: "1.5rem",
    zIndex: 10,
    fontWeight: 700,
    marginLeft: "5%",
    marginTop: isMobile ? "40%" : "25%",
    maxWidth: isMobile ? "100%" : "70%",
  };

  const heroTitleStyle = {
    fontSize: isMobile ? "2rem" : "5rem",
    color: "white",
    zIndex: 10,
    fontWeight: 800,
    marginTop: "1%",
    bottom: 0,
    margin: 0,
    marginLeft: "5%",
  };

  const subtitleStyle = {
    color: "white",
    fontSize: "1.4rem",
    fontWeight: 500,
    marginLeft: "5%",
    marginTop: "1%",
    maxWidth: "70%",
  };

  useAnalytics();

  const createLead = async () => {
    if (email.includes("@")) {
      try {
        await axios.post(`${config.API_URL}/default/leads/${email}`);

        localStorage.setItem("lead_email", email);
        // after BETA: navigate to /funnel
        navigate("/alpha");
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 409) {
          setError(t("email_exists"));
        }
      }
    }

    storeAnalyticsEvent({
      category: "/home:hero",
      action: "clicked",
      label: "Email Input",
    });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    storeAnalyticsEvent({
      category: "/home:hero",
      action: "typed",
      label: "Email Input",
    });
  };

  const mobileImageStyle = {
    marginTop: "-100%",
    width: "100%",
    position: "relative",
  };

  return (
    <section style={styles} ref={ref}>
      <Container
        itemsPerRow={isMobile ? 1 : 2}
        itemWidth="100%"
        style={{ width: "90%" }}
        columnWidths={isMobile ? ["100%"] : ["70%", "30%"]}
      >
        <div>
          <p style={noteStyle}>{t("#1 in Rental Rights")}</p>
          <h1 style={heroTitleStyle}>{t("hero_headline")}</h1>
          <h2 style={subtitleStyle}>
            {t(
              "smartmieter will help solve the problem with your rental and ensure you are properly compensated."
            )}
          </h2>
          <Container
            style={{ marginLeft: "5%", width: "70%", marginTop: "4%" }}
            itemWidth="auto"
            itemsPerRow={isMobile ? 1 : 2}
            columnWidths={isMobile ? ["100%"] : ["70%", "30%"]}
          >
            <Input
              style={{
                height: "3.2rem",
                borderRadius: "100px",
                paddingLeft: "20px",
                border: "none",
                fontWeight: 600,
              }}
              onEnter={createLead}
              onChange={(e) => handleEmailChange(e)}
              placeholder="name@example.com"
            ></Input>
            <button
              onClick={createLead}
              style={{
                backgroundColor: "black",
                color: "white",
                width: "10rem",
                borderRadius: "100px",
                textAlign: "center",
                height: "3.2rem",
                fontSize: "1.3rem",
                fontWeight: 600,
                zIndex: 10,
                position: "relative",
                border: "none",
                marginLeft: "5%",
                cursor: "pointer",
              }}
            >
              {t("Start")}
            </button>
          </Container>
          <p style={{ marginLeft: "5%", color: "white" }}>{error}</p>
        </div>
        <img
          style={
            isMobile ? mobileImageStyle : { width: "110%", marginTop: "60%" }
          }
          src={url}
        ></img>

        <Container
          style={{
            width: "90%",
            marginLeft: "5%",
            bottom: "2%",
            position: "absolute",
          }}
          itemWidth="auto"
          columnWidths={["17%", "17%", "17%", "17%", "17%", "17%"]}
          itemsPerRow={6}
        >
          <Container
            style={{ width: "70%" }}
            itemWidth="auto"
            itemsPerRow={2}
            columnWidths={["20%", "80%"]}
          >
            <img
              style={{ width: "90%" }}
              src={require("../../shared/assets/check-white.png")}
            ></img>
            <p style={{ fontWeight: 600, color: "white" }}>{t("High Rent")}</p>
          </Container>
          <Container
            style={{ width: "70%" }}
            itemWidth="auto"
            itemsPerRow={2}
            columnWidths={["20%", "80%"]}
          >
            <img
              style={{ width: "90%" }}
              src={require("../../shared/assets/check-white.png")}
            ></img>
            <p style={{ fontWeight: 600, color: "white" }}>{t("Cold Water")}</p>
          </Container>
          <Container
            style={{ width: "70%" }}
            itemWidth="auto"
            itemsPerRow={2}
            columnWidths={["20%", "80%"]}
          >
            <img
              style={{ width: "90%" }}
              src={require("../../shared/assets/check-white.png")}
            ></img>
            <p style={{ fontWeight: 600, color: "white" }}>{t("No Heating")}</p>
          </Container>
          <Container
            style={{ width: "70%" }}
            itemWidth="auto"
            itemsPerRow={2}
            columnWidths={["20%", "80%"]}
          >
            <img
              style={{ width: "90%" }}
              src={require("../../shared/assets/check-white.png")}
            ></img>
            <p style={{ fontWeight: 600, color: "white" }}>
              {t("High Servicecharges")}
            </p>
          </Container>
          <Container
            style={{ width: "70%" }}
            itemWidth="auto"
            itemsPerRow={2}
            columnWidths={["20%", "80%"]}
          >
            <img
              style={{ width: "90%" }}
              src={require("../../shared/assets/check-white.png")}
            ></img>
            <p style={{ fontWeight: 600, color: "white" }}>{t("Mold")}</p>
          </Container>
          <Container
            style={{ width: "70%" }}
            itemWidth="auto"
            itemsPerRow={2}
            columnWidths={["20%", "80%"]}
          >
            <img
              style={{ width: "90%" }}
              src={require("../../shared/assets/check-white.png")}
            ></img>
            <p style={{ fontWeight: 600, color: "white" }}>
              {t("More than ")}
              <br></br> {t("30 Reasons")}
            </p>
          </Container>
        </Container>
      </Container>
    </section>
  );
});
