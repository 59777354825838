import { SplitPage, Container } from "../shared/components";
import { HomeButton } from "../Auth/components";
import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import { getStageConfig } from "../config/config";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import { useAuth } from "../Auth/provider";
import "../i18n";

export const Checkout = ({ return_url, style }) => {
  const { user, jwtToken } = useAuth();
  const config = getStageConfig();
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const plan = queryParams.get("plan");

  const Plans = {
    basic: "price_1QZyq7BQODzeSQeQrADlxV6h",
    premium: "",
  };

  const fetchClientSecret = async () => {
    try {
      const session = await axios.post(
        `${config.API_URL}/default/payments/checkout`,
        {
          priceId: Plans[plan ?? "basic"],
          tenantId: user?.tenantId,
          return_url,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      return session.data.client_secret;
    } catch (error) {
      console.error(`error while fetching client secret: ${error}`);
    }
  };

  const stripePromise = loadStripe(
    "pk_test_51QGfVnBQODzeSQeQ0lMUgcJq98ZyVbRKADz5qppJLUWUoMDMTRihfgQELVgLhN2b3w4bTpcw06fCVrTWLKNi8Xd100M5Ex94cm"
  );

  const options = {
    fetchClientSecret,
  };

  return (
    <div style={{ width: "70%", marginLeft: "2%", ...style }}>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

const Payment = () => {
  const { t, i18n } = useTranslation();

  useAnalytics();

  const stripePromise = loadStripe(
    "pk_test_51QGfVnBQODzeSQeQ0lMUgcJq98ZyVbRKADz5qppJLUWUoMDMTRihfgQELVgLhN2b3w4bTpcw06fCVrTWLKNi8Xd100M5Ex94cm"
  );

  return (
    <div>
      <head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
      </head>

      <SplitPage color="white" float="left">
        <HomeButton style={{ marginLeft: "7%" }} />

        <h1
          style={{
            fontSize: "2rem",
            fontWeight: 800,
            marginTop: "10%",
            bottom: 0,
            marginLeft: "8%",
          }}
        >
          {t("payment")}
        </h1>

        <Elements stripe={stripePromise}>
          <Checkout></Checkout>
        </Elements>
      </SplitPage>
      <SplitPage float="right" style={{ height: "135vh" }}>
        <Container
          itemsPerRow={1}
          itemWidth="80%"
          style={{ margin: "5rem 0rem 0rem 5rem" }}
        >
          <h3 style={{ color: "white", fontSize: "2rem" }}>
            {t("payment_headline")}
          </h3>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              marginTop: "2rem",
              borderRadius: "20px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 500,
                marginLeft: "1.5rem",
              }}
            >
              1
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t("Receive a free consultation about your possibilities.")}
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "20px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 500,
                marginLeft: "1.5rem",
              }}
            >
              2
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t("Access quick and around the clock help at your convenience")}
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "20px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 500,
                marginLeft: "1.5rem",
              }}
            >
              3
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t(
                "We'll put you in contact with specialized Attorneys in our Network"
              )}
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "20px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 500,
                marginLeft: "1.5rem",
              }}
            >
              4
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t("You receive the rights you are entitled to.")}
            </p>
          </Container>
        </Container>
      </SplitPage>
    </div>
  );
};
export default Payment;
