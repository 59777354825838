import { useTranslation } from "react-i18next";
import { useAnalytics } from "../analytics";
import { Container, DarkBlurredBackground } from "../shared/components";
import { Header } from "../shared/header";
import { Footer } from "../shared/footer";
import "../i18n";
import { useState } from "react";
import { EmailInput } from "../Home/components/emailinput";

export const Landlords = () => {
  const { t, i18n } = useTranslation();
  useAnalytics();

  const [emailVisible, setEmailVisible] = useState(false);

  return (
    <div>
      <Header staticColor={true}></Header>
      <Container
        itemWidth="100%"
        columnWidths={["100%"]}
        style={{ width: "100%", marginBottom: "5%", textAlign: "center" }}
      >
        <h1
          style={{
            fontSize: "3rem",
            fontWeight: 900,
            marginTop: "25%",
          }}
        >
          {t("landlords.headline")}
        </h1>
        <p style={{ color: "#AAA6A6", width: "50%", margin: "0 auto" }}>
          {t("landlords.subheadline")}
        </p>

        <p
          style={{
            color: "#AAA6A6",
            width: "50%",
            margin: "0 auto",
            marginTop: "2%",
          }}
        >
          {t("landlords.subheadline2")}
        </p>
        <img
          style={{
            width: "70%",
            display: "block",
            margin: "0 auto",
            marginTop: "5%",
          }}
          src={require("./assets/smartmieter_house_visualizer.png")}
        ></img>
        <div style={{ marginTop: "10%", position: "relative" }}>
          <p
            style={{
              position: "absolute",
              marginLeft: "5%",
              marginTop: "5%",
              fontSize: "2.5rem",
              fontWeight: 600,
            }}
          >
            {t("landlords.how.headline")}
          </p>

          <Container
            margin="0"
            style={{
              position: "absolute",
              width: "90%",
              marginTop: "15%",
              marginLeft: "5%",
            }}
            itemsPerRow={3}
            itemWidth="auto"
          >
            <img
              style={{ width: "90%" }}
              src={require(
                `../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_landlord_step_1.png`
              )}
            ></img>
            <img
              style={{ width: "90%" }}
              src={require(
                `../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_landlord_step_2.png`
              )}
            ></img>
            <img
              style={{ width: "90%" }}
              src={require(
                `../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_landlord_step_3.png`
              )}
            ></img>
          </Container>
          <img
            style={{ width: "100%" }}
            src={require("./assets/smartmieter_background.png")}
          ></img>
        </div>

        <div>
          <img
            onClick={() => setEmailVisible(true)}
            style={{ width: "80%", marginTop: "5%" }}
            src={require(
              `../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_landlord_waitlist_visualizer.png`
            )}
          ></img>
        </div>
      </Container>
      <Footer></Footer>
      {emailVisible ? (
        <DarkBlurredBackground></DarkBlurredBackground>
      ) : undefined}
      {emailVisible ? (
        <EmailInput
          type="waitlist"
          onClose={() => setEmailVisible(false)}
          funnel={false}
          feature={"Landlord Portal"}
        ></EmailInput>
      ) : undefined}
    </div>
  );
};
