import { useRef, useState } from "react";
import {
  Input,
  Button,
  SplitPage,
  Container,
  HyperLink,
} from "../shared/components.js";
import { AuthError, HomeButton } from "./components.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./provider.jsx";
import { useTranslation } from "react-i18next";
import { storeAnalyticsEvent, useAnalytics } from "../analytics.js";
import "../i18n";

const Auth = () => {
  const { t, i18n } = useTranslation();
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [readyForSignIn, setReadyForSignIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authState, setAuthError] = useState(false);
  const [authError, setError] = useState("");

  const emailRef = useRef();
  const passwordRef = useRef();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useAnalytics();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const continueClicked = () => {
    if (!email) {
      setAuthError(true);
      setError("emailMissing");
      return;
    }

    if (!emailRegex.test(email)) {
      setAuthError(true);
      setError("invalidEmail");
      return;
    }

    setAuthError(false);
    setItems((prevItems) => [...prevItems, `Item ${prevItems.length + 1}`]);
    setReadyForSignIn(true);
  };

  const handleSignIn = async () => {
    switch (true) {
      case !email.trim():
        setAuthError(true);
        setError("emailMissing");
        return;
      case !password.trim():
        setAuthError(true);
        setError("passwordMissing");
        return;
      case !email.trim() && !password.trim():
        setAuthError(true);
        setError("inputMissing");
        return;
    }

    setAuthError(false);

    try {
      const authResult = await signIn(email, password);
      if (authResult.redirect) {
        navigate(authResult.redirect);
      }
    } catch (error) {
      setAuthError(error.authError);
      setError(error.type);
    }
  };

  return (
    <div>
      <SplitPage color="white" float="left">
        <HomeButton />
        <Container
          style={{ width: "30vw" }}
          itemsPerRow={1}
          itemWidth="30vw"
          margin="10rem 0rem 50rem 10rem"
        >
          <h1>Login</h1>
          <h2>{t("What is your e-mail?")}</h2>
          <Input
            ref={emailRef}
            onChange={handleEmailChange}
            placeholder={t("Please enter your e-mail")}
            type="email"
            onEnter={() => passwordRef.current?.focus()}
          ></Input>
          {items.map((item, index) => (
            <Input
              ref={passwordRef}
              onChange={handlePasswordChange}
              type="password"
              placeholder={t("Enter password")}
              onEnter={readyForSignIn ? handleSignIn : continueClicked}
            ></Input>
          ))}
          <Button
            onClick={readyForSignIn ? handleSignIn : continueClicked}
            label={readyForSignIn ? t("Login") : t("Continue")}
          ></Button>
          <AuthError visibility={authState} type={authError}></AuthError>
          {/*<h1 style={{ textAlign: "center" }}>Social Login</h1>*/}
          <HyperLink
            onClick={() =>
              storeAnalyticsEvent({
                category: "/login",
                action: "clicked",
                label: "Register",
              })
            }
            // BETA: change back to /register
            href="/alpha"
          >
            {t("Or register here")}
          </HyperLink>
        </Container>
      </SplitPage>
      <SplitPage float="right">
        <Container
          itemsPerRow={1}
          itemWidth="80%"
          style={{ margin: "5rem 0rem 0rem 5rem" }}
        >
          <h3 style={{ color: "white", fontSize: "2rem" }}>
            {t("How to assert your")}
            <span style={{ color: "black" }}>{t("Rights")} </span>
            {t("in four steps")}
          </h3>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              1
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t("Receive a free consultation about your possibilities.")}
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              2
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t("Access quick and around the clock help at your convenience")}
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              3
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t(
                "We'll put you in contact with specialized Attorneys in our Network"
              )}
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              4
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t("You receive the rights you are entitled to.")}
            </p>
          </Container>
        </Container>
      </SplitPage>
    </div>
  );
};
export default Auth;
