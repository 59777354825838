import { useAnalytics } from "../../analytics";
import { Pill } from "../../App/components";
import { Button, Container, ImageButton } from "../../shared/components";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const ServiceCharge = ({
  highServiceChargeClicked,
  highRentClicked,
}) => {
  const { t, i18n } = useTranslation();

  useAnalytics();

  return (
    <div style={{ height: "40rem", width: "100%", marginTop: "10%" }}>
      <Container
        itemsPerRow={2}
        itemWidth="auto"
        style={{ marginLeft: "10%", width: "90%" }}
        columnWidths={["50%", "50%"]}
      >
        <Container margin="0" gap="0" itemsPerRow={1} itemWidth="auto">
          <Pill
            style={{
              fontWeight: 600,
              bottom: 0,
              whiteSpace: "nowrap",
              width: "50%",
            }}
            label={t("Approximately 30% of new rentals")}
          ></Pill>
          <h1
            style={{
              margin: 0,
              fontWeight: 900,
              fontSize: "4rem",
              whiteSpace: "nowrap",
              height: "3.5rem",
              top: 0,
            }}
          >
            {t("HIGH RENT")}
          </h1>
          <p
            style={{
              marginTop: "6rem",
              position: "absolute",
              width: "30%",
              fontSize: "1.2rem",
            }}
          >
            {t(
              "In many cases unlawfully high rent is demanded. Receive rent back that surpasses the 'rental mirror' or the rent break."
            )}
          </p>
          <ImageButton
            style={{
              backgroundColor: "black",
              borderRadius: "10px",
              color: "white",
              position: "absolute",
              marginTop: "14rem",
              height: "3.3rem",
            }}
            onClick={highRentClicked}
            label={t("Inform me once available")}
          ></ImageButton>
        </Container>
        <Container margin="0" gap="0" itemsPerRow={1} itemWidth="auto">
          <Pill
            style={{
              fontWeight: 600,
              bottom: 0,
              whiteSpace: "nowrap",
              width: "40%",
            }}
            label={t("20-30% are calculated too highly")}
          ></Pill>
          <h1
            style={{
              margin: 0,
              fontWeight: 900,
              fontSize: "4rem",
              height: "3.5rem",
              top: 0,
            }}
          >
            {t("HIGH SERVICECHARGES")}
          </h1>
          <p
            style={{
              marginTop: "6rem",
              position: "absolute",
              width: "30%",
              fontSize: "1.2rem",
            }}
          >
            {t(
              "You received a surprisingly high servicecharge bill. We'll double-check it and lower it if possible."
            )}
          </p>
          <ImageButton
            style={{
              backgroundColor: "black",
              borderRadius: "10px",
              color: "white",
              position: "absolute",
              marginTop: "14rem",
              height: "3.3rem",
            }}
            onClick={highServiceChargeClicked}
            label={t("Check & receive reimbursement")}
          ></ImageButton>
        </Container>
      </Container>
    </div>
  );
};
