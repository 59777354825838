import { useNavigate } from "react-router-dom";
import { Container, HyperLink, Button } from "./components";
import { useTranslation } from "react-i18next";
import "../i18n";

export const Footer = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const handleGetHelp = () => {
    // after BETA: /register
    navigate("/alpha");
  };

  const styles = {
    footer: {
      backgroundColor: "#16171D",
      color: "#FFFFFF",
      width: "100%",
      height: "70vh",
      display: "grid",
      alignItems: "center",
      position: "relative",
      bottom: 0,
    },
    content: {
      textAlign: "center",
    },
    text: {
      fontSize: "14px",
      margin: 0,
    },
  };

  return (
    <footer style={styles.footer}>
      <Container
        itemWidth="inherit"
        style={{
          marginLeft: "5%",
          marginTop: "5%",
        }}
      >
        <p
          style={{
            fontWeight: 900,
            color: "868080",
            fontSize: "1.2rem",
            textAlign: "left",
            marginBottom: "0",
          }}
        >
          {t("ENSURE YOUR RENTAL RIGHTS WITH SMARTMIETER")}
        </p>
        <p
          style={{
            fontWeight: 900,
            fontSize: "2.5rem",
            textAlign: "left",
            marginTop: "0",
          }}
        >
          {t("GET THE HELP THAT YOU DESERVE")}
        </p>
        <Button
          onClick={() => handleGetHelp()}
          style={{
            width: "15rem",
            borderRadius: "10rem",
            float: "left",
            marginTop: "0",
          }}
          label="Hilfe bekommen"
        ></Button>
      </Container>

      <Container itemsPerRow={4} style={{ marginLeft: "5%" }}>
        <Container style={{ marginLeft: "0" }}>
          <h3 style={{ fontSize: "1.5rem", textAlign: "left" }}>smartmieter</h3>
          <HyperLink
            onClick={() => {}}
            href={"/about"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
            }}
          >
            About Us
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"/terms"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
              marginTop: "2%",
            }}
          >
            {t("Our Policies")}
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"/privacy"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
              marginTop: "2%",
            }}
          >
            Privacy Notice
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"/landlords"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
              marginTop: "2%",
            }}
          >
            Für Vermieter
          </HyperLink>
        </Container>
        <Container style={{ marginLeft: "20%", top: 0, marginTop: 0 }}>
          <h2
            style={{
              fontSize: "1.2rem",
              textAlign: "left",
              fontWeight: "500",
              marginTop: "0",
            }}
          >
            {t("Help")}
          </h2>
          <HyperLink
            onClick={() => {}}
            href={"/contact"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
            }}
          >
            {t("Contact")}
          </HyperLink>
          {
            <HyperLink
              onClick={() => {}}
              href={"/contact"}
              style={{
                color: "white",
                textDecoration: "none",
                textAlign: "left",
                float: "left",
                fontWeight: "600",
                marginTop: "2%",
              }}
            >
              {t("Receive help")}
            </HyperLink>
          }
        </Container>
        {/*<Container style={{ marginLeft: "20%" }}>
          <h2
            style={{
              fontSize: "1.2rem",
              textAlign: "left",
              fontWeight: "500",
              marginTop: "0",
            }}
          >
            Abos
          </h2>
          <HyperLink
            href={
              "/subscriptions?type=standard"
            } 
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
            }}
          >
            Standard
          </HyperLink>
          <HyperLink
            href={
              "/subscriptions?type=premium"
            } 
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
              marginTop: "2%",
            }}
          >
            Premium
          </HyperLink>
        </Container>*/}
        <Container style={{ marginLeft: "20%" }}>
          <h2
            style={{
              fontSize: "1.2rem",
              textAlign: "left",
              fontWeight: "500",
              marginTop: "10%" /**fml */,
            }}
          >
            Socials
          </h2>
          <HyperLink
            onClick={() => {}}
            href={
              "https://www.instagram.com/smart.mieter/"
            } /** add instagram url here */
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
            }}
            externalLink={true}
          >
            Instagram
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={
              "https://www.linkedin.com/company/smart-mieter/about/?viewAsMember=true"
            }
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
              marginTop: "2%",
            }}
            externalLink={true}
          >
            LinkedIn
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"https://x.com/smart_mieter"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
              fontWeight: "600",
              marginTop: "2%",
            }}
            externalLink={true}
          >
            X/Twitter
          </HyperLink>
        </Container>
        <br></br> <br></br>
      </Container>
    </footer>
  );
};
