import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
};

export const storeAnalyticsEvent = (event) => {
  console.log(`clicked ${event.action} on ${event.category}`);
  ReactGA.event({
    category: event.category,
    action: event.action,
    label: event.label,
  });
};
