import { useNavigate } from "react-router-dom";
import { useAnalytics } from "../../analytics";
import { Button, Container } from "../../shared/components";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const Finish = ({}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  localStorage.setItem("funnel_step", 7);

  useAnalytics();

  const handleContinueClick = () => {
    navigate("/app");
  };

  return (
    <div>
      <Container
        itemsPerRow={2}
        itemWidth="auto"
        columnWidths={["10%", "90%"]}
        style={{ marginLeft: "10rem", marginTop: "10%" }}
      >
        <img
          style={{ width: "40%" }}
          src={require("../assets/smartmieter_check-circle.png")}
        ></img>
        <p style={{ fontSize: "1.3rem", fontWeight: 700 }}>
          {t("The check is complete")}
        </p>
      </Container>
      <p style={{ fontSize: "1.1rem", fontWeight: 500, marginLeft: "10rem" }}>
        {t(
          "We'll take care of the case and inform you of any new information "
        )}
        <br></br> {t("or possible imbursements.")}
      </p>
      <Button
        onClick={handleContinueClick}
        style={{ width: "20%", marginLeft: "10rem", marginTop: "2%" }}
        label={t("Coninue to case")}
      ></Button>
    </div>
  );
};
