import { Container, ContinueButton } from "../shared/components";
import { caseStatus } from "../shared/models";

export const CaseCardMenu = ({
  title,
  status,
  caseId,
  showStatus,
  onClick,
}) => {
  const styles = {
    backgroundColor: "#32006B",
    height: "10rem",
    width: "19rem",
    borderRadius: "10px",
    position: "relative",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      cursor: "pointer",
  };

  const statusCardStyles = {
    backgroundColor: "#F2F2F2",
    minWidth: "60%",
    maxWidth: "80%",
    height: "20%",
    borderRadius: "5px",
    marginTop: "2%",
    marginRight: "2%",
    float: "right",
    color: "black",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  };

  const containerStyle = {
    color: "white",
    fontSize: "1.4rem",
    fontWeight: "700",
    marginLeft: "5%",
    position: "absolute",
    marginTop: "30%",
  };

  return (
    <div style={styles} onClick={onClick}>
      {showStatus ? (
        <div style={statusCardStyles}>
          <p>{caseStatus[status]}</p>
        </div>
      ) : undefined}
      <Container
        itemsPerRow={2}
        itemWidth="fit-content"
        style={{
          ...containerStyle,
          display: "flex",
          alignItems: "center",
        }}
      >
        <p style={{ marginRight: "1%", minWidth: "100%" }}>{title}</p>
        <ContinueButton onClick={onClick}></ContinueButton>
      </Container>
    </div>
  );
};
