import { useTranslation } from "react-i18next";
import "../i18n";
export const caseStatus = {
  resolved: "Abgeschlossen",
  opened: "Geöffnet",
  contact_landlord: "Kontakt zu Vermieter",
  contact_attorney: "Kontakt zu Anwalt",
  response_needed: "Antwort ausstehend",
  chat_consultation: "Erstberatung",
};
