import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const Privacy = ({}) => {
  const { t, i18n } = useTranslation();
  useAnalytics();

  return (
    <div>
      <Header staticColor={true}></Header>
      <Container
        itemWidth="100%"
        columnWidths={["100%"]}
        style={{ width: "100%" }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "3rem",
            fontWeight: 900,
            marginTop: "25%",
          }}
        >
          {t("SMARTMIETER DATA PROTECTION")}
        </h1>
        <p style={{ color: "#AAA6A6", width: "50%", marginLeft: "25%" }}>
          {t(
            "Thank you for your interest in SMARTMIETER. The protection of your personal data is very important to us at SMARTMIETER. We are committed to handling your data securely and responsibly. This privacy policy explains clearly and comprehensibly what data we collect, how we use it and what rights you have with regard to your data. Our aim is to offer you the greatest possible transparency and to give you control over your data."
          )}
        </p>
        <Notice></Notice>
      </Container>
      <Footer></Footer>
    </div>
  );
};

const SectionTitle = ({ children }) => (
  <h3 style={{ fontSize: "1.5em", margin: "1em 0", fontWeight: "bold" }}>
    {children}
  </h3>
);

const Notice = () => {
  const { t, i18n } = useTranslation();
  return (
    <div
      style={{
        width: "50%",
        marginLeft: "25%",
        marginTop: "20%",
        marginBottom: "10%",
      }}
    >
      <h2>{t("Privacy policy for SMARTMIETER")}</h2>
      <p>
        {t(
          "The protection of your personal data is particularly important to us at SMARTMIETER. We attach great importance to transparency and would like to make it clear to you what data we collect, how we use it and what rights you have in relation to your data. This privacy policy informs you about the type, scope and purpose of the processing of personal data on our website and our services."
        )}
      </p>

      <SectionTitle>
        {t("1. Person responsible for data processing")}
      </SectionTitle>
      <p>
        {t(
          "Responsible for the processing of your personal data within the meaning of the General Data Protection Regulation (GDPR):"
        )}
        <br />
        {t("smartmieter UG (haftungsbeschränkt)")}
        <br />
        {t("Erbachstraße 13, Magstadt 71106")}
        <br />
        {t("contact@smartmieter.com")}
        <br />
      </p>

      <SectionTitle>
        {t("2. Collection and storage of personal data")}
      </SectionTitle>
      <p>
        {t(
          "We only collect and process your personal data if this is permitted by law or if you have given your consent. The following data may be collected:"
        )}
        <ul>
          <li>
            {t("Basic data during registration (name, e-mail address, etc.)")}
          </li>
          <li>{t("Payment data for chargeable services")}</li>
          <li>{t("Communication data if you contact us")}</li>
          <li>
            {t(
              "Automatically collected data when using our website (e.g. IP address, browser type, access times)"
            )}
          </li>
        </ul>
      </p>

      <SectionTitle>{t("3. Processing purposes and legal bases")}</SectionTitle>
      <p>
        {t(
          "We process your data for the following purposes and based on the respective legal bases:"
        )}
        <ul>
          <li>
            {t(
              "To fulfill contractual obligations pursuant to Art. 6 para. 1 lit. b GDPR, in particular for the provision of our consulting services."
            )}
          </li>
          <li>
            {t(
              "On the basis of your consent in accordance with Art. 6 para. 1 lit. a GDPR, e.g. for sending newsletters."
            )}
          </li>
          <li>
            {t(
              "To safeguard legitimate interests in accordance with Art. 6 para. 1 lit. f GDPR, e.g. to improve our services or to prevent fraud."
            )}
          </li>
          <li>
            {t(
              "To fulfill legal requirements pursuant to Art. 6 para. 1 lit. c GDPR."
            )}
          </li>
        </ul>
      </p>

      <SectionTitle>{t("4. Disclosure of data to third parties")}</SectionTitle>
      <p>
        {t(
          "Your personal data will only be passed on to third parties if this is necessary for the fulfillment of the contract, if you have expressly consented or if we are legally obliged to do so. The recipients may include:"
        )}
        <ul>
          <li>
            {t("Partner lawyers for the provision of consulting services")}
          </li>
          <li>{t("Payment service providers for transaction processing")}</li>
          <li>
            {t(
              "IT service providers for operating and maintaining our website"
            )}
          </li>
        </ul>
      </p>

      <SectionTitle>{t("5. Storage and deletion of data")}</SectionTitle>
      <p>
        {t(
          "We only store your personal data as long as it is necessary to fulfill the purposes mentioned or legal retention periods require it. After the expiration of the retention periods, your data will be deleted in accordance with legal regulations."
        )}
      </p>

      <SectionTitle>{t("6. Your rights as a data subject")}</SectionTitle>
      <p>
        {t("You have the following rights regarding your personal data:")}
        <ul>
          <li>{t("Right to access stored data (Art. 15 GDPR)")}</li>
          <li>{t("Right to correct inaccurate data (Art. 16 GDPR)")}</li>
          <li>
            {t("Right to erasure (‘Right to be forgotten’) (Art. 17 GDPR)")}
          </li>
          <li>{t("Right to restriction of processing (Art. 18 GDPR)")}</li>
          <li>{t("Right to data portability (Art. 20 GDPR)")}</li>
          <li>{t("Right to object to processing (Art. 21 GDPR)")}</li>
          <li>
            {t(
              "Right to revoke consent at any time with effect for the future (Art. 7 para. 3 GDPR)"
            )}
          </li>
        </ul>
        {t(
          "To exercise your rights, please contact the address mentioned above."
        )}
      </p>

      <SectionTitle>{t("7. Data security")}</SectionTitle>
      <p>
        {t(
          "We use technical and organizational measures to protect your data against unauthorized access, loss, or manipulation. Our security measures are regularly reviewed and adapted to current standards."
        )}
      </p>

      <SectionTitle>{t("8. Use of cookies and analysis tools")}</SectionTitle>
      <p>
        {t(
          "Our website uses cookies and similar technologies to analyze website usage and improve our services. You can adjust the use of cookies in your browser settings. For web analysis, we use [tool name], which collects and stores data about the use of our website. Further information can be found in our Cookie Policy."
        )}
      </p>

      <SectionTitle>{t("9. Changes to this privacy policy")}</SectionTitle>
      <p>
        {t(
          "We reserve the right to adapt this privacy policy to comply with changing legal requirements or changes to our services. The current version is available on our website."
        )}
      </p>

      <p>{t("Status: December 10, 2024")}</p>
    </div>
  );
};
