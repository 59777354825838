import { useAnalytics } from "../analytics";
import { Container } from "../shared/components";
import { Header } from "../shared/header";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getStageConfig } from "../config/config";
import { useState } from "react";
import { useEffect } from "react";
import { SelectionInput } from "../shared/components";
import { Input } from "../shared/components";
import { Button } from "../shared/components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth/provider";
import "../i18n";

export const Alpha = ({}) => {
  const { t } = useTranslation();
  const config = getStageConfig();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("mrs");
  const [email, setEmail] = useState("");
  const [language, setLanguage] = useState("");
  const [error, setError] = useState("");
  const { signIn } = useAuth();
  const [validInput, setValidInput] = useState(false);

  const navigate = useNavigate();

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  const validatePassword = () => passwordRegex.test(password);

  useEffect(() => {
    setLanguage(navigator.language || navigator.userLanguage);
  }, []);
  {
    /**erklären, wie man Feedback abgeben kann*/
  }

  const checkInput = () => {
    if (name.length === 0 || password.length === 0 || email.length === 0) {
      setError("Bitte füllen Sie alle Felder aus");
      return false;
    } else if (!validatePassword()) {
      setError(
        "Das Passwort muss mindestens 8 Zeichen lang sein, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten."
      );
      return false;
    }
    setValidInput(true);
    setError("");
    return true;
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    checkInput();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    checkInput();
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    checkInput();
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    checkInput();
  };

  const createUser = async () => {
    try {
      const result = await axios.post(`${config.API_URL}/default/users`, {
        fullName: name,
        email,
        address,
        language,
        password,
        city: "random",
        beta: true,
      });

      // BETA TODO: we changed some flags of "beta" to "alpha" but not all. The beta prop inside of user is also set to true for alpha users

      if (result.data && result.data.tenantId) {
        const newTenantId = result.data.tenantId;

        localStorage.setItem("funnel_tenant_id", newTenantId);

        try {
          const authResult = await signIn(email, password);
          if (authResult.redirect) {
            navigate("/app");
          }
        } catch (error) {
          setError(error.type);
        }
      } else {
        console.error("Error creating user:", result);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  useAnalytics();
  return (
    <div>
      <Header staticColor={false} hideSignUp={true}></Header>
      <Container
        itemWidth="100%"
        margin="0"
        gap="0"
        columnWidths={["100%"]}
        style={{ width: "100%" }}
      >
        <p
          style={{
            color: "white",
            fontWeight: 700,
            fontSize: "3rem",
            position: "fixed",
            marginLeft: "3%",
            marginTop: "5%",
            zIndex: 10,
          }}
        >
          {t("alpha.title")}
        </p>
        <div
          style={{
            position: "fixed",
            zIndex: 10,
            backgroundColor: "white",
            height: "38rem",
            width: "50rem",
            marginTop: "15%",
            marginLeft: "20rem",
            borderRadius: "10px",
          }}
        >
          <Container
            itemsPerRow={1}
            itemWidth="auto"
            gap="0"
            style={{ marginLeft: "25%", marginTop: "2%", width: "100%" }}
            columnWidths={["100%"]}
          >
            <p
              style={{ fontWeight: 500, bottom: 0, margin: 0, marginTop: "3%" }}
            >
              {t("common.address")}
            </p>
            <SelectionInput
              onChange={(event) => handleAddressChange(event)}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              options={[
                { label: t("common.female"), value: "ms" },
                { label: t("common.male"), value: "mr" },
                { label: t("common.other"), value: "other" },
              ]}
            ></SelectionInput>
            <p style={{ fontWeight: 500, margin: 0, marginTop: "4%" }}>
              {t("common.fullname")}
            </p>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder={t("components.enter.name")}
              value={name}
              onChange={(event) => handleNameChange(event)}
            ></Input>

            <p style={{ fontWeight: 500, margin: 0, marginTop: "4%" }}>
              {t("common.email")}
            </p>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder="email@example.com"
              value={email}
              onChange={(event) => handleEmailChange(event)}
            ></Input>

            <p
              style={{ fontWeight: 500, bottom: 0, margin: 0, marginTop: "4%" }}
            >
              {t("common.password")}
            </p>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder={t("components.choose.password")}
              type="password"
              onChange={(event) => handlePasswordChange(event)}
            ></Input>
            <p style={{ fontSize: "0.6rem", width: "50%" }}>
              {t("alpha.password_info")}
            </p>
            <p style={{ bottom: 0, marginTop: "1%" }}>{error}</p>
            <Button
              style={{
                backgroundColor: "#69ADFF",
                fontWeight: 600,
                width: "50%",
                height: "3.5rem",
              }}
              active={validInput}
              onClick={createUser}
              label={t("common.continue")}
            ></Button>
            <Button
              onClick={() => navigate("/login")}
              label={t("alpha.login")}
              style={{
                backgroundColor: "transparent",
                color: "black",
                width: "50%",
                fontSize: "0.8rem",
              }}
            ></Button>
          </Container>
        </div>
        <p
          style={{
            color: "white",
            fontSize: "1rem",
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "58%",
            zIndex: 10,
          }}
        >
          {t("alpha.helpus")}
        </p>
        <img
          src={require("./assets/smartmieter_retro_background.png")}
          style={{ width: "100%", position: "relative" }}
        ></img>
      </Container>
    </div>
  );
};
