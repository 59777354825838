import { DarkBlurredBackground } from "./components";

export const Dialog = ({
  type = "error" | "confirmation" | "warning" | "information",
  title,
  message,
  dismiss,
}) => {
  const dialogStyle = {
    width: "30rem",
    height: "15rem",
    backgroundColor: "white",
    borderRadius: "15px",
    position: "absolute",
    bottom: "10%",
    left: "50%",
    transform: "translateX(-50%)",
  };

  const imageSrc = {
    error: require("./assets/error.png"),
    confirmation: require("./assets/confirmation.png"),
    information: require("./assets/info.png"),
  }[type];

  setTimeout(dismiss, 4000);

  const defaultTitle = {
    error: "Ein Fehler ist aufgetreten",
    success: "",
  };

  const defaultMessage = {
    error: "Bitte versuchen Sie es erneut.",
    success: "",
  };

  return (
    <div>
      <DarkBlurredBackground></DarkBlurredBackground>
      <div style={dialogStyle}>
        <p
          style={{
            textAlign: "center",
            fontWeight: "600",
            marginTop: "8%",
            fontSize: "1.25rem",
          }}
        >
          {title ? title : defaultTitle[type]}
        </p>
        <p
          style={{
            textAlign: "center",
            fontWeight: "500",
            marginTop: "3%",
            fontSize: "1rem",
          }}
        >
          {message ? message : defaultMessage[type]}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <img style={{ width: "8%", marginTop: "3%" }} src={imageSrc}></img>
        </div>
        <div
          style={{
            width: "60%",
            height: "4px",
            backgroundColor: "#ccc",
            marginTop: "10px",
            position: "relative",
            borderRadius: "15px",
            left: "50%",
            transform: "translateX(-50%)",
            position: "absolute",
            bottom: "10%",
          }}
        >
          <div
            style={{
              width: "50%",
              height: "100%",
              backgroundColor: "#69ADFF",
              position: "absolute",
              borderRadius: "15px",
              animation: "loading 4s",
            }}
          ></div>
        </div>
        <style>
          {`
         @keyframes loading {
         
          0% { width: 0; }
           100% { width: 100%; }
         }
        `}
        </style>
      </div>
    </div>
  );
};
