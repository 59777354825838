import { getStageConfig } from "../config/config";
import { Button, CancelButton, Container } from "../shared/components";
import { DarkBlurredBackground } from "../shared/components";
import { useEffect, useState } from "react";
import axios from "axios";
import { getDateByISO, mapReason } from "../shared/helpers";
import { useAuth } from "../Auth/provider";
import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import { Dialog } from "../shared/dialog";
import "../i18n";
import { Pill } from "../App/components";

const TableRow = ({ type, date, onClick, index, offer = false }) => {
  const { t, i18n } = useTranslation();
  const tableRowStyle = {
    backgroundColor: "#E1EFFF",
    width: "32vw",
    height: "10vh",
    borderRadius: "10px",
    marginTop: "2%",
  };

  const image = {
    landlord: { src: require("./assets/person-blue.png"), width: "40%" },
    tenant: { src: require("./assets/letter-blue.png"), width: "45%" },
    property_management: {
      src: require("./assets/house-blue.png"),
      width: "35%",
    },
    attorney: { src: require("./assets/law-blue.png"), width: "35%" },
  }[type];

  const handleClick = () => {
    onClick(index);
  };

  const title = {
    landlord: t("Landlord"),
    property_management: t("Property Management Company"),
    tenant: t("Renter"),
    attorney: t("Attorney"),
  }[type];

  return (
    <div style={tableRowStyle} onClick={handleClick}>
      <Container
        columnWidths={["20%", "50%", "30%"]}
        itemWidth="auto"
        alignItems="auto"
        margin="0"
        gap="0"
        itemsPerRow={3}
      >
        <img
          src={image.src}
          style={{
            marginLeft: "20%",
            marginTop: "25%",
            width: image.width,
          }}
        ></img>
        <Container
          itemWidth="auto"
          gap="0"
          margin="0"
          style={{ marginTop: "2%" }}
        >
          <p
            style={{
              fontWeight: 800,
              fontSize: "1.1rem",
              margin: 0,
              marginTop: "6%",
              bottom: 0,
            }}
          >
            {title}
          </p>
          <p style={{ marginTop: "4%" }}>{date}</p>
        </Container>
        {offer ? (
          <Pill
            style={{
              marginLeft: "-3%",
              whiteSpace: "nowrap",
              width: "90%",
              marginTop: "2%",
            }}
            label={"Angebot erhalten"}
          ></Pill>
        ) : undefined}
      </Container>
    </div>
  );
};

export const Correspondence = ({ Case, onCancel, onAcceptSuggestion }) => {
  const { t, i18n } = useTranslation();
  const config = getStageConfig();
  const { user, jwtToken } = useAuth();
  const [index, setIndex] = useState(0);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [correspondence, setCorrespondences] = useState([]);

  const backgroundStyle = {
    width: "90vw",
    height: "90vh",
    borderRadius: "30px",
    backgroundColor: "white",
    zIndex: 10,
    position: "fixed",
    top: "5%",
    left: "5%",
  };

  const handleDismissDialog = () => {
    setDialogError(false);
  };

  useEffect(() => {
    console.log(`Case in correspondence: ${JSON.stringify(Case)}`);
    const fetchData = async () => {
      let result;
      try {
        result = await axios.get(
          `${config.API_URL}/default/correspondence/${Case.caseId}`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        console.log(`result: ${JSON.stringify(result)}`);
        setCorrespondences(result.data ?? []);
      } catch (error) {
        setDialogError(true);
      }
    };

    fetchData();
  }, []);

  useAnalytics();

  const handleButtonClick = () => {
    if (!correspondence || correspondence.length === 0) {
      console.error("Correspondence is null, empty, or index is out of bounds");
      setDialogError(true);
      setDialogMessage(
        "Es scheint bisher keine Nachrichten in diesem Fall zu geben."
      );
      return;
    }

    switch (correspondence[index].from) {
      case "landlord":
      case "property_management":
        onAcceptSuggestion();
        break;
      case "tenant":
        handleResolveCase();
        break;
      case "lawyer":
        //handleLawyerChat()
        break;
    }
  };

  const handleResolveCase = async () => {
    const result = await axios.put(
      `${config.API_URL}/default/cases/${user?.tenantId}?caseId=${Case.caseId}&status=resolved`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    if (result.status === 200) {
      // TODO: Case resolved. Ask for feedback on case and why it was resolved ("received payment", "mediated", "no longer interested")

      setDialogSuccess(true);
      setDialogTitle("Vielen Dank");
      setDialogMessage(
        "Der Fall wurde gelöst. Wir hoffen wir konnten Ihnen helfen."
      );
      setTimeout(onCancel, 4000);
    }
  };

  const title = {
    landlord: "Nachricht von Vermieter",
    tenant: "Nachricht von Mieter",
    property_management: "Nachricht von Hausverwaltung",
    lawyer: "Nachricht von Anwalt",
  }[correspondence[index]?.from];

  const handleRowClick = (index) => {
    setIndex(index);
  };

  const label = {
    landlord: "Antwort auswählen",
    tenant: "Fall schließen",
    lawyer: "Antworten",
    property_management: "Antwort auswählen",
  }[correspondence[index]?.from];

  return (
    <div>
      <DarkBlurredBackground></DarkBlurredBackground>
      <div style={backgroundStyle}>
        <Container
          style={{ width: "100%" }}
          itemsPerRow={3}
          itemWidth="auto"
          columnWidths={["60%", "30%", "5%"]}
        >
          <p
            style={{
              fontWeight: 600,
              fontSize: "2rem",
              marginTop: "3%",
              marginLeft: "3%",
            }}
          >
            {t("Messages in Case ")}
            {mapReason(Case.reason)[user?.language.substring(0, 2) ?? "de"]}
          </p>
          <p style={{ color: "#868080", textAlign: "right", margin: 0 }}>
            {t("Case opened on: ")}
            {getDateByISO(Case.createdAt)}
          </p>
          <CancelButton onClick={onCancel}></CancelButton>
        </Container>
        <Container
          style={{ width: "100%" }}
          itemsPerRow={2}
          alignItems="auto"
          itemWidth="auto"
          columnWidths={["40%", "60%"]}
        >
          <div
            style={{
              marginLeft: "5%",
              with: "40vw",
              height: "75vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {correspondence.map((item, index) => (
              <TableRow
                index={index}
                type={item?.from}
                date={getDateByISO(item?.sentAt)}
                onClick={(index) => handleRowClick(index)}
                offer={item?.offer}
              ></TableRow>
            ))}
          </div>

          <Container
            itemWidth="auto"
            style={{ width: "70%", marginLeft: "5%" }}
          >
            {correspondence[index]?.offer ? (
              <div>
                <p style={{ fontWeight: 700, fontSize: "1.2rem", margin: 0 }}>
                  Angebot Ihres Vermieters
                </p>
                <Container
                  style={{ marginTop: "3%" }}
                  itemsPerRow={2}
                  columnWidths={["auto", "auto"]}
                >
                  <Pill
                    style={{ height: "3rem" }}
                    label={correspondence[index]?.offer.compensation}
                  ></Pill>
                  <Pill
                    style={{ height: "3rem" }}
                    label={correspondence[index]?.offer.proposal}
                  ></Pill>
                </Container>
              </div>
            ) : undefined}
            <Container
              margin="0"
              style={{ with: "80%" }}
              itemsPerRow={2}
              columnWidths={["50%", "50%"]}
            >
              <p style={{ fontWeight: 700, fontSize: "1.2rem", margin: 0 }}>
                {title}
              </p>
              <p style={{ color: "#868080", margin: 0, textAlign: "right" }}>
                {correspondence[index]?.date}
              </p>
            </Container>
            <p>{correspondence[index]?.message.substring(0, 1500)}</p>
            <Container style={{ bottom: 0, right: 0 }}>
              <Button
                style={{
                  borderRadius: "100px",
                  bottom: "5%",
                  right: "5%",
                  position: "absolute",
                  width: "25%",
                }}
                onClick={() => handleButtonClick()}
                label={label}
              ></Button>
            </Container>
          </Container>
        </Container>
      </div>
      {dialogError ? (
        <Dialog
          type="error"
          message={dialogMessage}
          dismiss={handleDismissDialog}
        ></Dialog>
      ) : undefined}
      {dialogSuccess ? (
        <Dialog
          type="success"
          message={dialogMessage}
          dismiss={handleDismissDialog}
        ></Dialog>
      ) : undefined}
    </div>
  );
};
