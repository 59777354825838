import { useState, useEffect } from "react";
import {
  Input,
  Button,
  SplitPage,
  Container,
  HyperLink,
  SelectionInput,
} from "../shared/components";
import { AuthError, HomeButton } from "./components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getStageConfig } from "../config/config";
import { useTranslation } from "react-i18next";
import "../i18n";

const Register = () => {
  const { t, i18n } = useTranslation();
  const config = getStageConfig();

  const navigate = useNavigate();

  const [readyForRegister, setReadyForRegister] = useState(false);
  const [authState, setAuthError] = useState(false);
  const [authError, setError] = useState("");
  const [label, setButtonLabel] = useState("Weiter");

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("mrs");
  const [city, setCity] = useState("");
  const [password, setPassword] = useState("");
  const [inputs, setInputs] = useState([]);
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(navigator.language || navigator.languages[0]);
  }, []);

  const handleRegister = async () => {
    if (!password) {
      setAuthError(true);
      setError("passwordMissing");
      return;
    } else if (!validatePassword()) {
      console.log("password invalid");
      setAuthError(true);
      setError("weakPassword");
      return;
    }

    setAuthError(false);
    try {
      const response = await axios.post(`${config.API_URL}/default/users`, {
        fullName,
        email,
        city,
        password,
        language,
        address,
      });

      switch (response.status) {
        case 201:
          navigate("/payment");
          break;
        case 400:
        case 409:
          setAuthError(true);
          setError("default");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

  const inputPlaceholders = [
    "Geben Sie Ihre Email ein.",
    "Geben Sie Ihren Wohnort ein.",
    "Wählen Sie ein Passwort.",
  ];

  const inputTypes = ["email", "text", "password"];

  const handleNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleInputChange = (index, newValue) => {
    setAuthError(false);
    const updatedInputs = inputs.map((input, i) =>
      i === index ? { ...input, value: newValue } : input
    );

    switch (index) {
      case 0:
        setEmail(newValue);
        break;
      case 1:
        setCity(newValue);
        break;
      case 2:
        setPassword(newValue);
        break;
    }

    setInputs(updatedInputs);
  };

  const addInput = () => {
    const nextIndex = inputs.length;
    switch (nextIndex) {
      case 0:
        if (!validateName()) {
          setAuthError(true);
          setError("nameMissing");
          return;
        }
        break;
      case 1:
        if (!email) {
          setAuthError(true);
          setError("emailMissing");
          return;
        } else if (!validateEmail()) {
          setAuthError(true);
          setError("invalidEmail");
          return;
        }
        break;
      case 2:
        if (!city) {
          setAuthError(true);
          setError("cityMissing");
          return;
        }
        setButtonLabel("Zur Zahlung");
        setReadyForRegister(true);
        break;
      case 3:
        if (!password) {
          setAuthError(true);
          setError("passwordMissing");
          return;
        } else if (!validatePassword()) {
          setAuthError(true);
          setError("weakPassword");
          return;
        }

        setAuthError(false);
        break;
    }

    if (nextIndex < inputPlaceholders.length) {
      setInputs([
        ...inputs,
        {
          placeholder: inputPlaceholders[nextIndex],
          inputType: inputTypes[nextIndex],
        },
      ]);
    } else {
      return;
    }
  };

  const validateName = () => fullName.length > 3;

  const validateEmail = () => emailRegex.test(email);

  const validatePassword = () => passwordRegex.test(password);

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  return (
    <div>
      <SplitPage color="white" float="left">
        <HomeButton />
        <Container
          style={{ width: "30vw" }}
          itemsPerRow={1}
          itemWidth="30vw"
          margin="10rem 0rem 50rem 10rem"
        >
          <h1>{t("Registration")}</h1>
          <h2>{t("What is your name?")}</h2>
          <SelectionInput
            onChange={(event) => handleAddressChange(event)}
            options={[
              { label: t("Mrs."), value: "ms" },
              { label: t("Mr."), value: "mr" },
              { label: t("Other"), value: "other" },
            ]}
          ></SelectionInput>
          <Input
            onChange={handleNameChange}
            placeholder={t("Please enter your full name")}
            type="text"
            onEnter={readyForRegister ? handleRegister : addInput}
          ></Input>
          {inputs.map((input, index) => (
            <div>
              <Input
                onChange={(e) => handleInputChange(index, e.target.value)}
                placeholder={input.placeholder}
                type={input.inputType}
                onEnter={readyForRegister ? handleRegister : addInput}
              ></Input>
            </div>
          ))}
          <Button
            onClick={readyForRegister ? handleRegister : addInput}
            label={label}
          ></Button>
          <AuthError visibility={authState} type={authError}></AuthError>
          {/*<h1 style={{ textAlign: "center" }}>Social Sign Up</h1>*/}
          <HyperLink href="/login">{t("Or login here")}</HyperLink>
        </Container>
      </SplitPage>
      <SplitPage float="right">
        <Container
          itemsPerRow={1}
          itemWidth="80%"
          style={{ margin: "5rem 0rem 0rem 5rem" }}
        >
          <h3 style={{ color: "white", fontSize: "2rem" }}>
            {t("How to acces your ")}
            <span style={{ color: "black" }}>{t("Rights")} </span>
            {t("in four steps")}
          </h3>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              1
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t("Receive a free consultation about your possibilities.")}
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              2
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t("Access quick and around the clock help at your convenience")}
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              3
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t(
                "We'll put you in contact with specialized Attorneys in our Network"
              )}
            </p>
          </Container>
          <Container
            itemsPerRow={2}
            style={{
              backgroundColor: "#EFEFEF",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: 400,
                marginLeft: "1.5rem",
              }}
            >
              4
            </h3>
            <p style={{ justifyContent: "center" }}>
              {t("You receive the rights you are entitled to.")}
            </p>
          </Container>
        </Container>
      </SplitPage>
    </div>
  );
};
export default Register;
