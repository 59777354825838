import { Container, ImageButton } from "../../shared/components";
import "../../i18n";
import { useTranslation } from "react-i18next";

export const Numbers = ({ onClick }) => {
  const { t, i18n } = useTranslation();
  return (
    <div style={{ height: "70vh" }}>
      <h1
        style={{
          margin: 0,
          marginLeft: "10%",
          fontSize: "3.5rem",
          fontWeight: 800,
        }}
      >
        {t("Not-so-fun-fact about Renting.")}
        <br></br> {t("A majority of Renters have problems")} <br></br>
        {t("with their Rentals.")}
      </h1>

      <ImageButton
        style={{
          width: "20%",
          fontSize: "1.5rem",
          marginLeft: "10%",
          top: 0,
          textAlign: "left",
        }}
        label={t("You are not alone")}
        colorScheme="dark"
        onClick={onClick}
      ></ImageButton>

      <Container
        itemsPerRow={3}
        itemWidth="auto"
        style={{ marginLeft: "10%", width: "70%", marginTop: "10%" }}
        margin="0"
      >
        <Container itemsPerRow={1} itemWidth="auto" margin="0" gap="0">
          <p
            style={{
              margin: 0,
              fontWeight: 900,
              fontSize: "2.5rem",
              bottom: 0,
            }}
          >
            24M
          </p>
          <p style={{ top: 0 }}>{t("Households in Rentals")}</p>
        </Container>
        <Container itemsPerRow={1} itemWidth="auto" margin="0" gap="0">
          <p
            style={{
              margin: 0,
              fontWeight: 900,
              fontSize: "2.5rem",
              bottom: 0,
            }}
          >
            20%
          </p>
          <p style={{ top: 0 }}>{t("Percentage with Deficiencies")}</p>
        </Container>
        <Container itemsPerRow={1} itemWidth="auto" margin="0" gap="0">
          <p
            style={{
              margin: 0,
              fontWeight: 900,
              fontSize: "2.5rem",
              bottom: 0,
            }}
          >
            100€
          </p>
          <p style={{ top: 0 }}>{t("avg. rent reduction per month")}</p>
        </Container>
      </Container>
    </div>
  );
};
