import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const EasyHelp = () => {
  const { t, i18n } = useTranslation();
  const titleStyles = {
    fontSize: "2.5rem",
    fontWeight: 800,
  };

  console.log(`language: ${i18n.language}`);
  useAnalytics();

  return (
    <div>
      <Container
        itemsPerRow={2}
        itemWidth="fit-content"
        columnWidths={["50%", "50%"]}
        style={{
          marginLeft: "5%",
          height: "50vh",
          position: "relative",
        }}
      >
        <div>
          <p style={titleStyles}>
            {t("WE HELP")} <br></br>
            {t("SOLVE YOUR ISSUES")}
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: 600,
              marginTop: "7%",
              color: "#AAA6A6",
              width: "70%",
            }}
          >
            <span style={{ color: "black" }}>{t("We know")}</span>{" "}
            {t(
              "that you have better things to do than concern yourself with your rental issues."
            )}
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: 600,
              marginTop: "7%",
              color: "#AAA6A6",
              width: "70%",
            }}
          >
            <span style={{ color: "black" }}>
              {t("Stress, time and money")}
            </span>{" "}
            {t("to solve your issue isn't necessary with smartmieter.")}
          </p>
          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: 600,
              marginTop: "7%",
              color: "#AAA6A6",
              width: "70%",
            }}
          >
            <span style={{ color: "black" }}>
              {t("We'll help around the clock")}
            </span>{" "}
            {t(
              "to ensure that your problem gets solved and that you are adequately compensated."
            )}
            <span style={{ color: "black" }}> {t("Start for free")}</span>
          </p>
        </div>
        <img
          style={{ marginLeft: "5%", width: "75%", marginTop: "5%" }}
          src={require(
            `../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_product_display.png`
          )}
        ></img>
      </Container>
    </div>
  );
};
