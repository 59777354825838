import { useState } from "react";
import { CancelButton, HyperLink } from "../shared/components";
import { Container } from "../shared/components";
import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";

export const Account = ({ account, onClick }) => {
  const { t, i18n } = useTranslation();
  const styles = {
    backgroundColor: "white",
    height: "100%",
    width: "25%",
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 20,
  };

  const textStyles = {
    fontWeight: "600",
    textAlign: "left",
    position: "absolute",
    bottom: "50px",
  };

  useAnalytics();

  return (
    <div style={styles}>
      <Container itemsPerRow={1} style={{ marginLeft: "10%" }}>
        <Container itemsPerRow={2} style={{ marginTop: "5%" }}>
          <p
            style={{
              textAlign: "left",
              fontSize: "1.5rem",
              fontWeight: "600",
            }}
          >
            {t("Account")}
          </p>
          <CancelButton onClick={onClick}></CancelButton>
        </Container>

        <p style={{ ...textStyles, top: "100px" }}>
          {account ? account.firstName + " " + account.lastName : ""}
        </p>

        <p style={{ ...textStyles, top: "140px" }}>
          {account ? account.city : ""}
        </p>

        <p style={{ ...textStyles, top: "180px" }}>
          {account ? account.email : ""}
        </p>

        <Container itemsPerRow={1}>
          <p style={{ ...textStyles, fontStyle: "italic" }}>
            {t("smartmieter member since")} {account ? account.memberSince : ""}
          </p>
          <HyperLink
            href={"/imprint"}
            style={{
              ...textStyles,
              textDecoration: "none",
              fontWeight: "700",
              bottom: "20px",
              marginLeft: "11%",
              left: 0,
            }}
          >
            {t("About smartmieter")}
          </HyperLink>
        </Container>
      </Container>
    </div>
  );
};
