import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "./components";
import { useAuth } from "../Auth/provider";
import { storeAnalyticsEvent } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "./mobile";

export const Header = ({
  onAccountClick,
  parentRef,
  nestedRef,
  hideSignUp = false,
  staticColor = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [feedbackHeight, setFeedbackHeight] = useState("10rem");
  const { t, i18n } = useTranslation();
  const [bgColor, setBgColor] = useState("");
  const { user } = useAuth();
  const isMobile = useResponsiveStyles();

  let scrollTimeout = null;
  const color = staticColor
    ? "black"
    : bgColor === "rgba(0, 0, 0, 0)"
      ? "black"
      : "white";

  let newColor;
  let parentColor;
  useEffect(() => {
    if (!parentRef || !parentRef.current) return;
    const handleScroll = () => {
      if (parentRef.current && nestedRef.current) {
        parentColor = window.getComputedStyle(
          parentRef.current
        ).backgroundColor;
        newColor = window.getComputedStyle(nestedRef.current).backgroundColor;

        const nestedRefRect = nestedRef.current.getBoundingClientRect();

        const isNestedRefVisible =
          nestedRefRect.top < window.innerHeight && nestedRefRect.bottom > 0;
        if (!isNestedRefVisible) {
          setBgColor(parentColor);
        } else {
          setBgColor(newColor);
        }
        console.log(bgColor);
      }

      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      setFeedbackHeight("3rem");
      scrollTimeout = setTimeout(() => {
        console.log("timeout");
        setFeedbackHeight("10rem");
      }, 150);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [parentRef, nestedRef]);

  const headerStyles = {
    position: "fixed",
    backgroundColor: "transparent",
    width: "100%",
    zIndex: 10,
    top: 0,
  };

  const link = {
    de: "https://forms.gle/Hq4h4pxbwDMC6XbR7",
    en: "https://forms.gle/9cDUxTSaisdzgqej6",
  }[i18n.language];

  const handleFeedbackClick = () => {
    storeAnalyticsEvent({
      category: location.pathname + location.search,
      action: "clicked",
      label: "Feedback",
    });

    window.location.href = link;
  };

  return (
    <header style={headerStyles}>
      <Container
        itemsPerRow={isMobile ? 2 : 4}
        columnWidths={isMobile ? ["50%", "50%"] : ["25%", "50%", "25%"]}
        itemWidth={isMobile ? "50%" : "100%"}
        style={{ width: "100%" }}
      >
        {/**TODO: remove style for LogoButton with SNM-31 */}
        <LogoButton
          style={{ marginLeft: "10%" }}
          color={color}
          isMobile={isMobile}
        />
        {false ? (
          <Container
            itemsPerRow={5}
            itemWidth="fit-content"
            style={{ width: "100%", display: "flex", marginTop: "2.5%" }}
          >
            <HeaderButton color={color}>{t("Contract")}</HeaderButton>
            <HeaderButton color={color}>{t("Consultation")}</HeaderButton>
            <HeaderButton color={color}>{t("Processes")}</HeaderButton>
            <HeaderButton color={color}>{t("Partner Firms")}</HeaderButton>
            <AccountButton color={color} onClick={onAccountClick}>
              {t("Account")}
            </AccountButton>
          </Container>
        ) : undefined}
        {user?.beta ? (
          <div
            onClick={handleFeedbackClick}
            style={{
              position: "fixed",
              right: "2%",
              width: "10rem",
              height: feedbackHeight,
              backgroundColor: "black",
              borderRadius: "20px",
              cursor: "pointer",
            }}
            onMouseEnter={() => setFeedbackHeight("10rem")}
          >
            <p
              style={{
                zIndex: 10,
                color: "white",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              {t("feedback")}
            </p>
            <img
              style={{
                opacity: feedbackHeight == "10rem" ? 1 : 0,
                zIndex: 10,
                width: "50%",
                left: "25%",
                transform: "translateX(50%)",
              }}
              src={require("./assets/smartmieter_feedback_icon.png")}
            ></img>
          </div>
        ) : undefined}
        {!user?.beta && !hideSignUp ? (
          // BETA: Change button label
          <SignUpButton color={color} url="/alpha" isMobile={isMobile}>
            {t("Alpha")}
          </SignUpButton>
        ) : undefined}
      </Container>
    </header>
  );
};

const HeaderButton = ({ url, children, color }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url); // Redirect to the specified URL
  };

  return (
    <button
      onClick={() => handleClick()}
      style={{
        border: "none",
        appearance: "none",
        backgroundColor: "transparent",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: "pointer",
        whiteSpace: "nowrap",
        color,
      }}
    >
      {children}
    </button>
  );
};

const LogoButton = ({ style, url, color, isMobile }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleClick = () => {
    if (!user || !user.accountStatus === "inactive") {
      navigate("/");
    } else {
      navigate("/app");
    }
    storeAnalyticsEvent({
      category: "/home",
      action: "clicked",
      label: "Logo Clicked",
    });
  };

  const desktopStyle = {
    border: "none",
    appearance: "none",
    backgroundColor: "transparent",
    fontWeight: 700,
    fontSize: "2rem",
    marginLeft: "50%",
    marginTop: "3%",
    cursor: "pointer",
    whiteSpace: "nowrap",
  };

  const mobileStyle = {
    border: "none",
    appearance: "none",
    backgroundColor: "transparent",
    fontWeight: 900,
    fontSize: "3rem",
    fontStyle: "italic",
    marginLeft: "50%",
    marginTop: "3%",
    cursor: "pointer",
    whiteSpace: "nowrap",
  };

  return (
    <button
      onClick={() => handleClick()}
      style={{
        color,
        ...(isMobile ? mobileStyle : desktopStyle),
        ...style,
      }}
    >
      {isMobile ? "s" : "smartmieter"}
    </button>
  );
};

const SignUpButton = ({ url, children, color, isMobile }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url); // Redirect to the specified URL
  };

  const computedStyles = {
    white: { color: "black", backgroundColor: "white" },
    black: { color: "white", backgroundColor: "#69ADFF" },
  };

  return (
    <button
      onClick={() => handleClick()}
      style={{
        width: "12rem",
        padding: "10px 20px",
        fontSize: "1.3rem",
        backgroundColor: "69ADFF",
        border: "none",
        fontWeight: 600,
        borderRadius: "25px",
        cursor: "pointer",
        marginTop: "3%",
        marginLeft: isMobile ? "0" : "20%",
        ...computedStyles[color],
        color: "white",
        background: "linear-gradient(135deg, #D469FF 0%, #FFD3CB 100%)",
      }}
    >
      {children}
    </button>
  );
};

const AccountButton = ({ url, children, onClick, color }) => {
  return (
    <button
      onClick={onClick}
      style={{
        padding: "10px 20px",
        fontSize: "16px",
        border: "none",
        backgroundColor: "transparent",
        cursor: "pointer",
        color,
      }}
    >
      {children}
    </button>
  );
};
