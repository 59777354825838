import { useAuth } from "./provider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  let { user, jwtToken } = useAuth();
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedJwtToken = localStorage.getItem("jwtToken");
    if (storedUser) {
      console.log(`storedUser: ${JSON.stringify(storedUser)}`);
      user = JSON.parse(storedUser);
    }

    if (storedJwtToken) {
      console.log("jwtToken", storedJwtToken);
      jwtToken = storedJwtToken;
    }

    console.log(`routegard user: ${JSON.stringify(user)}`);
    if (!user || !user.accountStatus === "active") {
      navigate("/login");
    }
  }, [user, jwtToken, navigate]);

  return user ? children : null;
};
