import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";
export const AuthError = ({ visibility, type }) => {
  const { t, i18n } = useTranslation();
  const errorMessages = {
    emailMissing: t("Please enter your e-mail."),
    passwordMissing: t("Please enter your password."),
    nameMissing: t("Please enter your name."),
    cityMissing: t("Please enter your city of residence."),
    inputMissing: t("Please enter your e-mail and password."),
    invalidEmail: t("Please enter a valid e-mail."),
    weakPassword: t(
      "Your password needs to be at least 8 characters and must contain at least one letter and number."
    ),
    denied: t("Invalid e-mail or password"),
    default: t("An unknown error has occured"),
  };

  const errorMessage = errorMessages[type] || errorMessages.default;

  if (!visibility) return null;

  return <p style={{ color: "#721c24" }}>{errorMessage}</p>;
};

export const HomeButton = ({ style }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/"); // TODO: Redirect to the specified URL
  };
  return (
    <button
      onClick={() => handleClick()}
      style={{
        marginLeft: "5%",
        marginTop: "2%",
        fontSize: "1.7rem",
        fontWeight: 700,
        backgroundColor: "white",
        border: "none",
        cursor: "pointer",
        transition: "background-color 0.3s",
        ...style,
      }}
    >
      smartmieter
    </button>
  );
};
