import { useAnalytics } from "../analytics";
import {
  DarkBlurredBackground,
  CancelButton,
  Container,
} from "../shared/components";
import { useTranslation } from "react-i18next";
import "../i18n";

export const Action = ({
  title = "This is how we'll proceed",
  subtitle = "These are your options",
  options,
  onClick,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const backgroundStyle = {
    width: "50vw",
    height: "70vh",
    borderRadius: "30px",
    backgroundColor: "white",
    zIndex: 10,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(50%, 20%)",
  };

  const titleStyle = {
    fontWeight: "700",
    fontSize: "2rem",
    margin: 0,
    marginLeft: "10%",
    marginTop: "8%",
    bottom: 0,
  };

  const subtitleStyle = {
    marginLeft: "10%",
    fontSize: "1rem",
    color: "#868080",
    fontWeight: "500",
    top: 0,
  };

  useAnalytics();

  const handleOptionClick = async (option) => {
    onClick(option);
  };

  return (
    <div style={{ position: "absolute" }}>
      <DarkBlurredBackground></DarkBlurredBackground>
      <div style={backgroundStyle}>
        <Container
          style={{ width: "100%", margin: 0, rowGap: 0, columnGap: 0 }}
          itemWidth="auto"
          columnWidths={["80%", "20%"]}
          itemsPerRow={2}
        >
          <div>
            <p style={titleStyle}>{t(title)}</p>
            <p style={subtitleStyle}>{t(subtitle)}</p>
          </div>

          <CancelButton onClick={() => onCancel()}></CancelButton>
        </Container>
        <Container
          itemWidth="auto"
          style={{ margin: 0, rowGap: 0, columnGap: 0, marginTop: "5%" }}
        >
          <p style={{ marginLeft: "10%", width: "80%" }}>
            {t(
              "We'll communicate with your landlord, in order to solve the issue ASAP"
            )}
          </p>
          <Container
            itemsPerRow={3}
            columnWidths={["20%", "60%", "20%"]}
            style={{
              width: "40vw",
              height: "8vh",
              transform: "translate(10%, 0)",
              backgroundColor: "#C9E2FF",
              borderRadius: "10px",
              border: "1px solid #868080",
              cursor: "pointer",
            }}
            onClick={() => handleOptionClick("landlord")}
          >
            <img
              src={require("./assets/person-blue.png")}
              style={{ width: "12%", marginLeft: "10%" }}
            ></img>
            <p
              style={{ color: "#69ADFF", fontWeight: 800, fontSize: "1.2rem" }}
            >
              {t("Contact your landlord")}
            </p>
            <img
              style={{ width: "10%" }}
              src={require("../shared/assets/continue-arrow-dark.png")}
            ></img>
          </Container>
          <Container
            itemWidth="auto"
            style={{ margin: 0, rowGap: 0, columnGap: 0 }}
          >
            <p style={{ marginLeft: "10%", width: "80%" }}>
              {t(
                "We'll communicate with your property management company and try to solve the issue."
              )}
            </p>
            <Container
              itemsPerRow={3}
              columnWidths={["20%", "60%", "20%"]}
              style={{
                width: "40vw",
                height: "8vh",
                transform: "translate(10%, 0)",
                backgroundColor: "#C9E2FF",
                borderRadius: "10px",
                border: "1px solid #868080",
                cursor: "pointer",
              }}
              onClick={() => handleOptionClick("property-management")}
            >
              <img
                src={require("./assets/house-blue.png")}
                style={{ width: "12%", marginLeft: "10%" }}
              ></img>
              <p
                style={{
                  color: "#69ADFF",
                  fontWeight: 800,
                  fontSize: "1.2rem",
                }}
              >
                {t("Contact your property management company")}
              </p>
              <img
                style={{ width: "10%" }}
                src={require("../shared/assets/continue-arrow-dark.png")}
              ></img>
            </Container>
          </Container>
          <Container
            itemWidth="auto"
            style={{ margin: 0, rowGap: 0, columnGap: 0 }}
          >
            <p style={{ marginLeft: "10%", width: "80%" }}>
              {t("Get one of our partner attorneys involved")}
            </p>
            <Container
              itemsPerRow={3}
              columnWidths={["20%", "60%", "20%"]}
              style={{
                width: "40vw",
                height: "8vh",
                transform: "translate(10%, 0)",
                backgroundColor: "#C9E2FF",
                borderRadius: "10px",
                border: "1px solid #868080",
                cursor: "pointer",
              }}
              onClick={() => handleOptionClick("lawyer")}
            >
              <img
                src={require("./assets/law-blue.png")}
                style={{ width: "12%", marginLeft: "10%" }}
              ></img>
              <p
                style={{
                  color: "#69ADFF",
                  fontWeight: 800,
                  fontSize: "1.2rem",
                }}
              >
                {t("Contact attorney")}
              </p>
              <img
                style={{ width: "10%" }}
                src={require("../shared/assets/continue-arrow-dark.png")}
              ></img>
            </Container>
          </Container>
        </Container>
      </div>
    </div>
  );
};
