import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { AuthProvider } from "./Auth/provider";
import Auth from "./Auth/login";
import App from "./App";
import Register from "./Auth/register";
import { Routes, Route, HashRouter } from "react-router-dom";
import Home from "./Home";
import { ProtectedRoute } from "./Auth/routeguard";
import Payment from "./Payment";
import { Contact } from "./Legal/Contact";
import { Chat } from "./Chat/chat";
import { Contract } from "./Contract/contract";
import { Terms } from "./Legal/Terms";
import { Privacy } from "./Legal/Privacy";
import ScrollToTop from "./scrollToTop";
import { AboutUs } from "./Legal/AboutUs";
import { ConfirmEmail } from "./Account/confim-email";
import { CasesOverview } from "./Cases/overview";
import { SignUpFunnel } from "./SignUp";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Landlords } from "./Marketing/landlords";
import { Cards } from "./Marketing/cards";
import { Story } from "./Marketing/story";
import { Alpha } from "./Marketing/alpha";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <HashRouter>
        <SpeedInsights />
        <Analytics />
        <ScrollToTop />
        <AuthProvider>
          <Routes>
            {/*<Redirect exact from="/" to="/index" />*/}
            {/*<Route path="/authenticate" component={Authenticate} />*/}
            <Route
              path="/contract"
              element={
                <ProtectedRoute>
                  <Contract />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cases"
              element={
                <ProtectedRoute>
                  <CasesOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />

            <Route
              path="/app"
              element={
                <ProtectedRoute>
                  <App />
                </ProtectedRoute>
              }
            />

            <Route path="*" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/landlords" element={<Landlords />} />

            <Route path="/payment" element={<Payment />} />
            <Route path="/login" element={<Auth />} />
            <Route path="/register" element={<Register />} />

            <Route path="/terms" element={<Terms />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/:email/confirm-email" element={<ConfirmEmail />} />
            <Route path="/funnel" element={<SignUpFunnel />} />

            <Route path="/cards" element={<Cards />} />
            <Route path="/story" element={<Story />} />
            <Route path="/alpha" element={<Alpha />} />
          </Routes>
        </AuthProvider>
      </HashRouter>
    </I18nextProvider>
  </React.StrictMode>
);

//reportWebVitals();
