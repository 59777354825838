import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const AboutUs = ({}) => {
  const { t, i18n } = useTranslation();
  const styles = {
    backgroundColor: "#69ADFF",
    height: "100vh",
    width: "100%",
    marginBottom: "5%",
    top: 0,
    margin: 0,
    position: "relative",
  };

  useAnalytics();

  return (
    <div style={{ overflowX: "hidden" }}>
      <Header staticColor={true}></Header>
      <div style={styles}>
        <Container
          itemWidth="100%"
          columnWidths={["100%"]}
          style={{ width: "100%", margin: 0 }}
        >
          <h2
            style={{
              marginLeft: "10%",
              fontSize: "2rem",
              fontWeight: 700,
              marginTop: "30%",
              bottom: 0,
              marginBottom: 0,
              color: "#E6E6E6",
            }}
          >
            {t("About us")}
          </h2>
          <h1
            style={{
              position: "absolute",
              marginTop: "0",
              marginLeft: "10%",
              fontSize: "5rem",
              fontWeight: 900,
              marginBottom: 0,
              color: "white",
              zIndex: 10,
            }}
          >
            {t("ALL THINGS RENT")}
          </h1>
          <h3
            style={{
              position: "absolute",
              marginLeft: "10%",
              fontSize: "2rem",
              fontWeight: 500,
              marginTop: "5%",
              color: "white",
              zIndex: 10,
            }}
          >
            {t("WE ASSIST WITH ALL THINGS RENT")}
          </h3>
          <img
            style={{
              position: "absolute",
              width: "80%",
              bottom: 0,
              marginBottom: 0,
              marginTop: "auto",
              marginRight: 0,
              right: 0,
            }}
            src={require("../assets/room-image.png")}
          ></img>
        </Container>
      </div>
      <div style={{ height: "150vh" }}>
        <p
          style={{
            fontWeight: 900,
            fontSize: "3rem",
            marginTop: "10%",
            marginLeft: "50%",
            marginBottom: 0,
          }}
        >
          {t("THE FOUNDERS")}
        </p>
        <p
          style={{
            color: "#AAA6A6",
            fontWeight: 500,
            fontSize: "1rem",
            marginTop: "0",
            marginLeft: "50%",
            width: "40%",
          }}
        >
          {t(
            "smartmieter was founded in 2024 by Friedrich & Max in order to ensure renters' rights. Through various methods smartmieter protects renters from shady landlord practices & ensures rental quality."
          )}
        </p>

        <Container
          itemWidth="50%"
          itemsPerRow={2}
          style={{ marginTop: "10%", marginLeft: "10%" }}
          columnWidths={["30%", "70%"]}
        >
          <div
            style={{
              width: "25rem",
              height: "25rem",
              backgroundColor: "#69ADFF",
              borderRadius: "1.5rem",
            }}
          ></div>
          <div>
            <p
              style={{
                color: "#AAA6A6",
                fontWeight: 500,
                fontSize: "1rem",
                marginTop: "0",
                marginBottom: 0,
                bottom: 0,
                marginLeft: "10%",
              }}
            >
              {t("Co-founder/CEO")}
            </p>
            <p
              style={{
                fontWeight: 800,
                fontSize: "3rem",
                whiteSpace: "nowrap",
                marginLeft: "10%",
                top: 0,
                marginTop: 0,
                botton: 0,
                marginBottom: 0,
              }}
            >
              FRIEDRICH BURK
            </p>
            <p
              style={{
                width: "80%",
                fontWeight: 500,
                fontSize: "1rem",
                textAlign: "left",
                marginLeft: "10%",
                top: 0,
                marginTop: 0,
              }}
            >
              {t(
                "Friedrich was born in 2001 & studied Data Science at UC Berkeley. Since then, he spent time as an AI/Analytics Professional for Deloitte in Munich."
              )}
            </p>
          </div>
        </Container>

        <Container
          itemWidth="50%"
          itemsPerRow={2}
          style={{ marginTop: "10%" }}
          columnWidths={["60%", "40%"]}
        >
          <div style={{ marginLeft: "70%", width: "100%" }}>
            <p
              style={{
                width: "100%",
                color: "#AAA6A6",
                fontWeight: 500,
                fontSize: "1rem",
                marginTop: "0",
                textAlign: "right",
                marginBottom: 0,
                bottom: 0,
              }}
            >
              {t("Co-founder/CEO")}
            </p>
            <p
              style={{
                width: "100%",
                fontWeight: 800,
                fontSize: "3rem",
                whiteSpace: "nowrap",
                textAlign: "right",
                top: 0,
                marginTop: 0,
                bottom: 0,
                marginBottom: 0,
              }}
            >
              MAX SCHNITZLER
            </p>
            <p
              style={{
                width: "100%",
                fontWeight: 500,
                fontSize: "1rem",
                textAlign: "right",
                top: 0,
                marginTop: 0,
              }}
            >
              {t(
                "Max was born in 2002 and became interested in law at an early age. At the age of 16, he began studying law for two semesters while at school. After this time, at the age of 18, he programmed an app for organization in schools and has been working as a computer scientist since 2022. Since 2024 he has been working with Friedrich on a solution for tenants to defend themselves against injustice."
              )}
            </p>
          </div>
          <div
            style={{
              width: "25rem",
              height: "25rem",
              backgroundColor: "#69ADFF",
              borderRadius: "1.5rem",
            }}
          ></div>
        </Container>
      </div>
      <p style={{ marginLeft: "5%", fontWeight: 600 }}>
        smartmieter UG (haftungsbeschränkt): Erbachstraße 13, Magstadt 71106
      </p>
      <Footer></Footer>
    </div>
  );
};
