import { FAQ } from "../../Home/components/faq";
import { Container } from "../../shared/components";
import { Header } from "../../shared/header";
import { useRef } from "react";
import { Footer } from "../../shared/footer";
import { useAnalytics } from "../../analytics";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const Contact = ({}) => {
  const { t, i18n } = useTranslation();
  const parentRef = useRef();
  const nestedRef = useRef(null);

  useAnalytics();

  return (
    <div ref={parentRef}>
      <Header
        parentRef={parentRef}
        nestedRef={nestedRef}
        staticColor={true}
      ></Header>
      <Container
        itemWidth="100%"
        style={{
          top: 0,
          left: 0,
          margin: 0,
          rowGap: 0,
          overflowX: "hidden",
          width: "100%",
        }}
      >
        <div>
          <Container
            itemWidth="100%"
            style={{
              height: "90vh",
              top: 0,
              left: 0,
              margin: 0,
              display: "block",
              width: "100%",
            }}
          >
            <img
              ref={nestedRef}
              style={{ top: 0, left: 0, width: "100%", position: "absolute" }}
              src={require("../assets/woman-image.jpg")}
            ></img>
            <h1
              style={{
                color: "white",
                position: "relative",
                marginTop: "30%",
                marginLeft: "10%",
                color: "#E6E6E6",
                marginBottom: 0,
                bottom: 0,
              }}
            >
              {t("Contact Us")}
            </h1>
            <h2
              style={{
                color: "white",
                position: "relative",
                fontSize: "4rem",
                top: 0,
                marginTop: 0,
                marginLeft: "10%",
              }}
            >
              {t("We're here to help")}
            </h2>
          </Container>
          <Container
            itemsPerRow={2}
            style={{ height: "60vh" }}
            itemWidth="50%"
            columnWidths={["50%", "50%"]}
          >
            <img
              style={{ width: "100%", marginLeft: "40%" }}
              src={require("../assets/support-image.png")}
            ></img>
            <div>
              <p style={{ fontSize: "3rem", fontWeight: 900, marginBottom: 0 }}>
                {t("Let's talk directly.")}
              </p>
              <p style={{ top: 0, fontSize: "1rem" }}>
                {t("We look forward to your e-mail at contact@smartmieter.com")}
              </p>
            </div>
          </Container>
        </div>
        <br></br>
        {/*  <FAQ></FAQ>*/}
        <br></br>
      </Container>
      <Footer></Footer>
    </div>
  );
};
