import {
  DarkBlurredBackground,
  CancelButton,
  Container,
  Button,
  Input,
  SelectionInput,
} from "../shared/components";
import axios from "axios";
import { getStageConfig } from "../config/config";
import { useEffect, useState } from "react";
import { insertName } from "../shared/helpers";
import { useAuth } from "../Auth/provider";
import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import { Dialog } from "../shared/dialog";
import "../i18n";

export const Send = ({
  recipientType,
  options,
  landlordInformation,
  reason,
  caseId,
  onCancel,
}) => {
  const backgroundStyle = {
    width: "50vw",
    height: "70vh",
    borderRadius: "30px",
    backgroundColor: "white",
    zIndex: 10,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(50%, 20%)",
  };

  const titleStyle = {
    fontWeight: "700",
    fontSize: "2rem",
    margin: 0,
    marginLeft: "10%",
    marginTop: "8%",
    bottom: 0,
  };

  const subtitleStyle = {
    marginLeft: "10%",
    fontSize: "1rem",
    color: "#868080",
    fontWeight: "500",
    top: 0,
  };
  const { t, i18n } = useTranslation();
  const config = getStageConfig();
  const { user, jwtToken } = useAuth();
  const [continueClicked, setContinueClicked] = useState(false);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState(
    user?.beta ? "" : "vermieter@smartmieter.com"
  );
  const [contactAddress, setContactAddress] = useState("mrs");
  const [index, setIndex] = useState(0);
  const [templateMessages, setTemplateMessages] = useState([]);
  const [response, setResponse] = useState("");
  const [message, setMessage] = useState("");
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${config.API_URL}/default/templates`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (result.status === 200) {
        setMessage(
          insertName(result.message, landlordInformation.name ?? contactName)
        );
      }
      setTemplateMessages(result.data);
    };

    setTemplateMessages(["TEMPLATE MESSAGE"]);
    setMessage(templateMessages[index]);
    fetchData();
  }, [message, index]);

  const handleDismissDialog = () => {
    setDialogError(false);
  };

  const handleContinueClicked = () => {
    if (!contactEmail) {
      setResponse(t("Please enter the name of your correspondent"));
    } else if (!contactEmail) {
      setResponse(t("Please enter an e-mail"));
    } else if (validateEmail()) {
      setContinueClicked(true);
      console.log("continue clicked: " + continueClicked);
    } else {
      setResponse(t("Please enter a valid e-mail"));
    }
  };

  const validateEmail = () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactEmail);

  const handleIndex = () => {
    setMessage(templateMessages[index]);
  };

  useAnalytics();

  const sendMessage = async () => {
    console.log("sending message");
    let result;

    try {
      result = await axios.post(
        `${config.API_URL}/default/correspondence/${caseId}`,
        {
          type: recipientType,
          caseId,
          message,
          reason,
          tenantId: user?.tenantId,
          recipientName: landlordInformation?.name ?? contactName,
          recipientEmail: landlordInformation?.email ?? contactEmail,
          recipientAddress: landlordInformation?.address ?? contactAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      setDialogSuccess(true);
      setDialogTitle("Ihre Nachricht wurde gesendet");
      setDialogMessage(
        "Wir benachrichtigen Sie, sobald wir eine Antwort erhalten."
      );
      setResponse(
        t(
          "Your message was sent. We'll let you know when we receive a response."
        )
      );
      setTimeout(onCancel, 5000);
    } catch (error) {
      setDialogError(true);
      setDialogTitle("Es gab ein Problem beim Senden Ihrer Nachricht.");
      setResponse(
        t("There was an error sending your message. Please try again.")
      );
    }
  };

  const handleAddressChange = (event) => {
    setContactAddress(event.target.value);
  };

  const handleEmailChange = (event) => {
    setContactEmail(event.target.value);
  };

  const handleNameChange = (event) => {
    setContactName(event.target.value);
  };

  // make configurable lawyer, landlord,
  return (
    <div style={{ position: "absolute" }}>
      <DarkBlurredBackground></DarkBlurredBackground>
      <div style={backgroundStyle}>
        <Container
          style={{ width: "100%", margin: 0, rowGap: 0, columnGap: 0 }}
          itemWidth="auto"
          columnWidths={["80%", "20%"]}
          itemsPerRow={2}
        >
          <div>
            <p style={titleStyle}>{t("Send message")}</p>
            <p style={subtitleStyle}>
              {t(
                "Choose your first correspondence with your landlord and we'll take care of the rest."
              )}
            </p>
          </div>
          <CancelButton onClick={() => onCancel()}></CancelButton>
        </Container>
        {continueClicked ? (
          <p style={{ marginLeft: "8%", width: "80%" }}>
            {templateMessages[0]}
          </p>
        ) : (
          <div style={{ marginLeft: "8%" }}>
            <p>{t("Name of your landlord")}</p>
            <Container
              style={{ width: "70%" }}
              itemsPerRow={2}
              itemWidth="auto"
              columnWidths={["20%", "75%"]}
            >
              <SelectionInput
                onChange={(event) => handleAddressChange(event)}
                options={[
                  { label: t("Mrs."), value: "ms" },
                  { label: t("Mr."), value: "mr" },
                  { label: t("Other"), value: "other" },
                ]}
              ></SelectionInput>
              <Input
                onChange={(event) => handleNameChange(event)}
                placeholder="Maximilian Mustermann"
                value={contactName}
              ></Input>
            </Container>

            <p>{t("E-mail of your landlord")}</p>
            <Input
              type="email"
              onChange={(event) => handleEmailChange(event)}
              style={{ width: "70%" }}
              value={user?.beta ? "vermieter@smartmieter.com" : contactEmail}
              placeholder="mustermann@gmail.com"
            ></Input>
          </div>
        )}

        <p
          style={{
            marginLeft: "8%",
            position: "absolute",
            bottom: "13%",
            fontSize: "0.9rem",
          }}
        >
          {response}
        </p>

        <Button
          style={{
            width: "40%",
            marginLeft: "8%",
            borderRadius: "100px",
            bottom: "5%",
            position: "absolute",
          }}
          onClick={() =>
            landlordInformation
              ? sendMessage()
              : continueClicked
                ? sendMessage()
                : handleContinueClicked()
          }
          label={continueClicked ? "Nachricht senden" : "Weiter"}
        ></Button>
      </div>
      {dialogError ? (
        <Dialog
          type="error"
          message={dialogMessage}
          dismiss={handleDismissDialog}
        ></Dialog>
      ) : undefined}
      {dialogSuccess ? (
        <Dialog
          type="success"
          message={dialogMessage}
          dismiss={handleDismissDialog}
        ></Dialog>
      ) : undefined}
    </div>
  );
};
