import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import CognitoUserPool from "./configuration.js";
import axios from "axios";
import { getStageConfig } from "../config/config.js";

const AuthContext = createContext();

const config = getStageConfig();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [jwtToken, setJwtToken] = useState(
    localStorage.getItem("jwtToken") || null
  );

  useEffect(() => {
    const storedJwtToken = localStorage.getItem("jwtToken");
    const storedUser = localStorage.getItem("user");

    if (storedUser && storedJwtToken) {
      try {
        setUser(JSON.parse(storedUser));
        setJwtToken(storedJwtToken);
      } catch (error) {
        localStorage.removeItem("user");
        localStorage.removeItem("jwtToken");
      }
    }
  }, []);

  const getUserByEmail = async (email, jwt) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/default/user/${email}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      console.log(`user response: ${JSON.stringify(response)}`);
      setUser(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      console.error(`error fetching user: ${error}`);
      setUser(null);
    }
  };

  const signIn = async (email, password) => {
    console.log("attempting sign in");
    try {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: CognitoUserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authDetails, {
          onSuccess: async (result) => {
            console.log("user authenticated");

            const jwt = result.getIdToken().getJwtToken();
            setJwtToken(jwt);
            localStorage.setItem("jwtToken", jwt);

            try {
              const userDetails = await getUserByEmail(email, jwt);
              setUser(userDetails);
              localStorage.setItem("user", JSON.stringify(userDetails));

              resolve({
                type: "",
                authError: false,
                redirect: "/app",
              });
            } catch (error) {
              reject({
                type: "fetch_user_error",
                authError: true,
                redirect: "/login",
              });
            }
          },
          onFailure: (error) => {
            setUser(null);
            reject({
              type: "denied",
              authError: true,
            });
          },
        });
      });
    } catch (error) {
      console.log("error occured");
      return {
        error: "",
        authError: true,
      };
    }
  };

  const signOut = async (email) => {
    try {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: CognitoUserPool,
      });

      await cognitoUser.signOut();
      setUser(null);
      setJwtToken(null);
      localStorage.removeItem("user");
      localStorage.removeItem("jwtToken");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, jwtToken, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const getCurrentUser = () => {
  const user = CognitoUserPool.getCurrentUser();

  if (user) {
    return new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          reject(err);
        } else if (session.isValid()) {
          resolve(user);
        } else {
          reject("Session is invalid");
        }
      });
    });
  } else {
    return Promise.reject("No user is currently logged in");
  }
};
