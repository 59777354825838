import { useEffect } from "react";
import { Button } from "../../shared/components";
import { Container } from "../../shared/components";
import { mapReason } from "../../shared/helpers";
import { useState } from "react";
import { useAnalytics } from "../../analytics";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const Result = ({ reason, onClick }) => {
  const { t, i18n } = useTranslation();
  localStorage.setItem("funnel_step", 2);
  const [language, setLanguage] = useState("de");

  useAnalytics();

  const safeReason = reason
    ? reason
    : localStorage.getItem("compensation_reason");
  const possibleReduction = localStorage.getItem("compensation_estimate");

  const mappedReason = mapReason(safeReason)[language.substring(0, 2)];

  useEffect(() => {
    setLanguage(navigator.language || navigator.userLanguage);
  }, []);

  return (
    <div>
      <Container
        style={{ marginLeft: "10rem", marginTop: "10%" }}
        columnWidths={["100%"]}
        itemWidth="auto"
        itemsPerRow={1}
      >
        <p
          style={{ fontWeight: 700, fontSize: "1.1rem", bottom: 0, margin: 0 }}
        >
          {t("We have good news!")}
        </p>
        <div
          style={{
            backgroundColor: "#F6F6F6",
            width: "40rem",
            height: "15rem",
            borderRadius: "10px",
            top: 0,
          }}
        >
          <p style={{ marginLeft: "5%", paddingTop: "3%", fontWeight: 600 }}>
            {t("In cases of")}{" "}
            <span style={{ fontWeight: 700 }}>{mappedReason}</span>{" "}
            {t("you are often entitled to compensation/a rent reduction")}
          </p>
          <Container
            style={{ marginLeft: "5%", marginTop: "3%" }}
            itemsPerRow={2}
            itemWidth="auto"
            columnWidths={["10%", "90%"]}
          >
            <img
              style={{ width: "50%" }}
              src={require("../assets/smartmieter_wrench.png")}
            ></img>
            <p style={{ fontWeight: 500, width: "70%" }}>
              {t("We'll ensure that you have")} {mappedReason}{" "}
              {t("as soon as possible.")}
              {/* Soll das geändert werden?*/}
            </p>
          </Container>
          <Container
            style={{ marginLeft: "5%" }}
            itemsPerRow={2}
            itemWidth="auto"
            columnWidths={["10%", "90%"]}
          >
            <img
              style={{ width: "50%" }}
              src={require("../assets/smartmieter_dollar.png")}
            ></img>
            <p style={{ fontWeight: 500, width: "70%" }}>
              {t("Imbursement/rent reduction - up to")} {possibleReduction}{" "}
              {t("per month.")}
            </p>
          </Container>
        </div>

        <Button
          style={{
            backgroundColor: "#69ADFF",
            fontWeight: 600,
            width: "25%",
            height: "3.5rem",
            marginTop: "5%",
          }}
          onClick={onClick}
          label={t("Continue")}
        ></Button>
      </Container>
    </div>
  );
};
