import { getStageConfig } from "../../config/config";
import { Button, Input, SelectionInput } from "../../shared/components";
import { Container } from "../../shared/components";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useAnalytics } from "../../analytics";
import { useTranslation } from "react-i18next";
import "../../i18n";

/// Ich weiß nicht ob ich die Errors übersetzen soll. Lass bescheid wenn das der Fall ist.

export const Contact = ({ type = "personal" | "landlord", onClick }) => {
  const { t, i18n } = useTranslation();
  localStorage.setItem("funnel_step", type === "personal" ? 3 : 4);
  const config = getStageConfig();

  useAnalytics();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [landlordName, setLandlordName] = useState("");
  const [landlordEmail, setLandlordEmail] = useState("");
  const [landlordAddress, setLandlordAddress] = useState("mrs");
  const [address, setAddress] = useState("mrs");
  const [validInput, setValidInput] = useState(false);
  const [language, setLanguage] = useState("");
  const [city, setCity] = useState("");

  const [error, setError] = useState("");

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  const validatePassword = () => passwordRegex.test(password);

  useEffect(() => {
    setLanguage(navigator.language || navigator.userLanguage);
  }, []);

  const checkInput = () => {
    switch (type) {
      case "personal":
        if (name.length === 0 || password.length === 0 || city.length === 0) {
          setError("Bitte füllen Sie alle Felder aus");
          return false;
        } else if (!validatePassword()) {
          setError(
            "Das Passwort muss mindestens 8 Zeichen lang sein, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten."
          );
          return false;
        }
        setValidInput(true);
        setError("");
        return true;
      case "landlord":
        if (landlordName.length === 0 || landlordEmail.length === 0) {
          setError("Bitte füllen Sie alle Felder aus");
          return false;
        } else if (!landlordEmail.includes("@")) {
          setError("Bitte geben Sie eine gültige Email ein");
          return false;
        }
        setValidInput(true);
        return true;
    }
  };

  const createUser = async () => {
    try {
      const result = await axios.post(`${config.API_URL}/default/users`, {
        fullName: name,
        email: localStorage.getItem("lead_email"),
        address,
        language,
        password,
        city,
        prompt: localStorage.getItem("funnel_prompt"),
      });

      if (result.data && result.data.tenantId) {
        const newTenantId = result.data.tenantId;

        localStorage.setItem("funnel_tenant_id", newTenantId);
      } else {
        console.error("Error creating user:", result);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const updateLandlordData = async () => {
    const id = localStorage.getItem("funnel_tenant_id");
    if (!id) {
      console.error("Cannot update: Tenant ID is not set.");
      return;
    }

    try {
      const result = await axios.put(`${config.API_URL}/default/users/${id}`, {
        sk: "PROFILE",
        landlord: {
          name: landlordName,
          email: landlordEmail,
          address: landlordAddress,
          type: "landlord",
        },
      });

      if (result.status === 200) {
        console.log(`result: ` + JSON.stringify(result.data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    localStorage.setItem("funnel_name", event.target.value);
    checkInput();
  };

  const handleLandlordAddressChange = (event) => {
    setLandlordAddress(event.target.value);
    localStorage.setItem("funnel_landlord_address", event.target.value);
    checkInput();
  };

  const handleLandlordNameChange = (event) => {
    setLandlordName(event.target.value);
    localStorage.setItem("funnel_landlord_name", event.target.value);
    checkInput();
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    localStorage.setItem("funnel_address", event.target.value);
    checkInput();
  };

  const handleLandlordEmailChange = (event) => {
    setLandlordEmail(event.target.value);
    localStorage.setItem("funnel_landlord_email", event.target.value);
    checkInput();
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
    localStorage.setItem("funnel_city", event.target.value);
    checkInput();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    checkInput();
  };

  const continueClicked = async () => {
    if (checkInput()) {
      if (type === "landlord") {
        await updateLandlordData();
      } else if (type === "personal") {
        await createUser();
      }
      onClick();
    }
  };

  return (
    <div>
      <Container
        style={{ marginLeft: "10rem", marginTop: "10%" }}
        columnWidths={["100%"]}
        itemWidth="auto"
        itemsPerRow={1}
      >
        <p
          style={{ fontWeight: 700, fontSize: "1.1rem", bottom: 0, margin: 0 }}
        >
          {
            type === "personal"
              ? "Wir brauchen weitere Details um Ihr Problem zu behandeln" //Here
              : "Wir benötigen Details zu Ihrem Vermieter" //Here
          }
        </p>
        {type === "personal" ? (
          <div>
            <p
              style={{ fontWeight: 500, bottom: 0, margin: 0, marginTop: "3%" }}
            >
              {t("Title")}
            </p>
            <SelectionInput
              onChange={(event) => handleAddressChange(event)}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              options={[
                { label: "Frau", value: "ms" },
                { label: "Herr", value: "mr" },
                { label: "Andere", value: "other" },
              ]}
            ></SelectionInput>
            <p style={{ fontWeight: 500, margin: 0, marginTop: "4%" }}>
              {t("First and last name")}
            </p>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder="Namen eingeben"
              value={localStorage.getItem("funnel_name")}
              onChange={(event) => handleNameChange(event)}
            ></Input>

            <p style={{ fontWeight: 500, margin: 0, marginTop: "4%" }}>
              {t("Location of Residence")}
            </p>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder="Stadt eingeben"
              value={localStorage.getItem("funnel_city")}
              onChange={(event) => handleCityChange(event)}
            ></Input>

            <p
              style={{ fontWeight: 500, bottom: 0, margin: 0, marginTop: "4%" }}
            >
              {t("Password")}
            </p>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder="Passwort festlegen"
              type="password"
              onChange={(event) => handlePasswordChange(event)}
            ></Input>
            <p style={{ fontSize: "0.6rem" }}>
              {t(
                "With your passowrd you can check the status of your case on our website."
              )}
            </p>
          </div>
        ) : (
          <div>
            <p
              style={{ fontWeight: 500, bottom: 0, margin: 0, marginTop: "5%" }}
            >
              {t("Title")}
            </p>
            <SelectionInput
              onChange={(event) => handleLandlordAddressChange(event)}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              options={[
                { label: "Frau", value: "ms" },
                { label: "Herr", value: "mr" },
                { label: "Andere", value: "other" },
              ]}
            ></SelectionInput>
            <p
              style={{ fontWeight: 500, bottom: 0, margin: 0, marginTop: "5%" }}
            >
              {t("First and last name")}
            </p>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder="Namen eingeben"
              value={localStorage.getItem("funnel_landlord_name")}
              onChange={(event) => handleLandlordNameChange(event)}
            ></Input>

            <p
              style={{ fontWeight: 500, bottom: 0, margin: 0, marginTop: "4%" }}
            >
              Email
            </p>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder="vermieter@example.com"
              value={localStorage.getItem("funnel_landlord_email")}
              onChange={(event) => handleLandlordEmailChange(event)}
            ></Input>
          </div>
        )}

        <p style={{ bottom: 0, marginTop: "1%" }}>{error}</p>
        <Button
          style={{
            backgroundColor: "#69ADFF",
            fontWeight: 600,
            width: "25%",
            height: "3.5rem",
          }}
          active={validInput}
          onClick={continueClicked}
          label="Weiter"
        ></Button>
      </Container>
    </div>
  );
};
