import axios from "axios";
import { useEffect } from "react";
import { getStageConfig } from "../config/config";
import { HomeButton } from "../Auth/components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";

export const ConfirmEmail = () => {
  const config = getStageConfig();
  const { t, i18n } = useTranslation();

  const { email } = useParams();

  const [status, setStatus] = useState("");

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const result = await axios.post(
          `${config.API_URL}/default/leads/${email}/waitlist/confirm`
        );

        if (result.status === 200) {
          setStatus("success");
        } else {
          setStatus("error");
        }
      } catch (error) {
        console.error(error);
      }
    };

    confirmEmail();
  });

  return (
    <div>
      <HomeButton />
      {status === "success" ? (
        <div style={{ marginLeft: "5%" }}>
          <h1>{t("Thank you!")}</h1>
          <p>{t("Your e-mail has been confirmed")}</p>
        </div>
      ) : (
        <div style={{ marginLeft: "5%" }}>
          <h1>{t("It's our fault, not yours.")}</h1>
          <p>{t("Unfortunately, we couldn't confirm your e-mail.")}</p>
        </div>
      )}
    </div>
  );
};
