import { Container } from "../../shared/components";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const SideMenu = ({ step = 1 }) => {
  const { t, i18n } = useTranslation();
  const stepIndicator = {
    1: require("../assets/steps/1.png"),
    2: require("../assets/steps/1.png"),
    3: require("../assets/steps/2.png"),
    4: require("../assets/steps/2.png"),
    5: require("../assets/steps/3.png"),
    6: require("../assets/steps/3.png"),
    7: require("../assets/steps/4.png"),
  }[step];

  const stepHierarchy = {
    1: [1, 2],
    2: [3, 4],
    3: [5, 6],
    4: [7],
  };

  const stepImage = {
    done: require("../../shared/assets/confirmation.png"),
    current: require("../assets/smartmieter_current.png"),
    future: require("../assets/smartmieter_future.png"),
  };

  const getMainStep = (currentStep) => {
    for (const [mainStep, subSteps] of Object.entries(stepHierarchy)) {
      if (subSteps.includes(currentStep)) {
        return parseInt(mainStep, 10);
      }
    }
    return null;
  };

  const getStepImage = (mainStep) => {
    const currentMainStep = getMainStep(step);

    if (step === 7) {
      return stepImage.done;
    }

    if (mainStep === currentMainStep) {
      return stepImage.current;
    }

    if (mainStep < currentMainStep) {
      return stepImage.done;
    }

    return stepImage.future;
  };

  return (
    <div>
      <Container
        style={{
          backgroundColor: "#F6F6F6",
          height: step === 6 ? "140vh" : "85vh",
          width: "20rem",
        }}
        margin="0"
        itemWidth="auto"
        alignItems="auto"
        itemsPerRow={1}
      >
        <h1 style={{ marginLeft: "10%", marginTop: "20%", bottom: 0 }}>
          smartmieter
        </h1>
        <Container
          style={{
            marginLeft: "2%",
            position: "absolute",
            top: "15%",
            width: "20rem",
          }}
          itemWidth="auto"
          itemsPerRow={2}
          margin="0"
          gap="0"
          columnWidths={["10%", "90%"]}
        >
          <img
            style={{ width: "25%", height: "13rem", marginTop: "2rem" }}
            src={stepIndicator}
          ></img>
          <Container
            margin="0"
            gap="0"
            itemsPerRow={1}
            itemWidth="auto"
            style={{ height: "13rem" }}
            columnWidths={["100%"]}
          >
            <Container
              itemWidth="auto"
              itemsPerRow={2}
              alignItems="center"
              columnWidths={["5%", "95%"]}
            >
              <img
                key={1}
                style={{ width: step > 2 ? "95%" : "70%" }}
                src={getStepImage(1)}
              ></img>
              <p style={{ fontWeight: 500 }}>
                {t("Please describe your issue which requires assistance")}
              </p>
            </Container>
            <Container
              itemWidth="auto"
              itemsPerRow={2}
              alignItems="center"
              columnWidths={["5%", "95%"]}
            >
              <img
                key={2}
                style={{ width: step > 4 ? "95%" : "70%" }}
                src={getStepImage(2)}
              ></img>
              <p style={{ fontWeight: 500 }}>{t("Additional Information")}</p>
            </Container>
            <Container
              itemWidth="auto"
              itemsPerRow={2}
              alignItems="center"
              columnWidths={["5%", "95%"]}
            >
              <img
                key={3}
                style={{ width: step > 6 ? "95%" : "70%" }}
                src={getStepImage(3)}
              ></img>
              <p style={{ fontWeight: 500 }}>{t("Optional: Documents")}</p>
            </Container>
            <Container
              itemWidth="auto"
              itemsPerRow={2}
              alignItems="center"
              columnWidths={["5%", "95%"]}
            >
              <img
                key={4}
                style={{ width: step >= 7 ? "95%" : "70%" }}
                src={getStepImage(4)}
              ></img>
              <p style={{ fontWeight: 500 }}>{t("Done")}</p>
            </Container>
          </Container>
        </Container>

        <img
          src={
            step === 6
              ? require("../assets/smartmieter_funnel_payment_deco.png")
              : require("../assets/smartmieter_funnel_decoration.png")
          }
          style={{
            width: "20rem",
            position: step === 6 ? "relative" : "absolute",
          }}
        ></img>
      </Container>
    </div>
  );
};
