import { Pill } from "../../App/components";
import { Container } from "../../shared/components";
import "../../i18n";
import { useTranslation } from "react-i18next";

export const Problems = ({ onClick }) => {
  const { t, i18n } = useTranslation();
  const iGot99ProblemsAndABitchAintOne = [
    t("I have no warm water in my rental."),
    t("I have mold in my rental."),
    t("I want to enforce a rental reduction."),
    t("I am constantly disturbed by loud noises."),
    t("I received a large bill for servicecharges."),
    t("I regularly have electrical problems in my rental."),
    t("My heat doesn't work during the winter."),
  ];

  return (
    <div style={{ height: "90vh", position: "relative", marginTop: "15%" }}>
      <h1 style={{ textAlign: "center", fontSize: "3rem", bottom: 0 }}>
        {t("You know your problem. We know the solution.")}
      </h1>
      <h2 style={{ textAlign: "center", fontSize: "1.5rem" }}>
        {t(
          "Explain your problem and we'll look to solve your issue as fast as possible."
        )}
      </h2>
      <Container
        itemWidth="auto"
        itemsPerRow={4}
        columnWidths={["25%", "25%", "25%", "25%"]}
        gap="10px"
        style={{
          position: "absolute",
          width: "90%",
          left: "5%",
          right: "5%",
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          margin: "2%",
          marginTop: "15%",
        }}
      >
        {iGot99ProblemsAndABitchAintOne.map((input, index) => (
          <Pill
            key={index}
            label={input}
            style={{ minWidth: "88%", marginLeft: "2%", marginRight: "2%" }}
            textStyle={{ fontSize: "0.8rem" }}
            onClick={onClick}
          />
        ))}
      </Container>
    </div>
  );
};
