import { storeAnalyticsEvent, useAnalytics } from "../../analytics";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const Partners = ({ onClick }) => {
  const { t, i18n } = useTranslation();
  const backgroundStyle = {
    width: "100%",
    height: "100vh",
    marginTop: "20%",
    position: "relative",
  };

  const titleStyles = {
    fontSize: "3rem",
    fontWeight: 900,
    width: "80%",
    lineHeight: "3.2rem",
    marginLeft: "5%",
    position: "absolute",
    color: "white",
    marginTop: "45%",
  };

  const subtitleStyles = {
    fontSize: "1.5rem",
    width: "80%",
    marginLeft: "5%",
    position: "absolute",
    color: "white",
    marginTop: "50%",
  };

  const buttonStyles = {
    backgroundColor: "transparent",
    width: "15rem",
    height: "3rem",
    fontSize: "1.1rem",
    borderRadius: "100px",
    fontWeight: 800,
    textAlign: "left",
    marginTop: "55%",
    position: "absolute",
    marginLeft: "5%",
    border: "none",
    color: "white",
  };

  useAnalytics();

  const handleButtonClick = () => {
    storeAnalyticsEvent({
      category: "/home:partners",
      action: "clicked",
      label: "Lets go",
    });
    onClick();
  };

  return (
    <div style={backgroundStyle}>
      <img
        style={{
          left: 0,
          bottom: 0,
          top: 0,
          right: 0,
          width: "100%",
          position: "absolute",
        }}
        src={require("../assets/smartmieter_lawyer_image.png")}
      ></img>
      <div>
        <p style={titleStyles}>{t("ONE TEAM, RENOWNED PARTNER FIRMS")}</p>
        <p style={subtitleStyles}>
          {t(
            "If we are unable to resolve the matter directly, we will involve our partner attorneys from renowned law firms to enforce your rights."
          )}
        </p>
        <button onClick={handleButtonClick} style={buttonStyles}>
          {t("Let's go")}
        </button>
      </div>
    </div>
  );
};
