import { Button, CancelButton, Input } from "../../shared/components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getStageConfig } from "../../config/config";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const EmailInput = ({
  funnel = true,
  type = "funnel",
  feature,
  onClose,
}) => {
  const config = getStageConfig();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const styles = {
    backgroundColor: "white",
    height: "70vh",
    width: "70vh",
    position: "fixed",
    top: "50%",
    left: "50%",
    borderRadius: "30px",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  };

  const subtitle = {
    funnel: "Im nächsten Schritt können Sie Ihr Problem beschreiben",
    waitlist: "Wir benachrichtigen Sie bei Neuigkeiten.",
  }[type];

  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const createLead = async () => {
    if (email.includes("@")) {
      try {
        await axios.post(`${config.API_URL}/default/leads/${email}`);
      } catch (error) {
        console.error(error);
      }
      localStorage.setItem("lead_email", email);
      navigate("/funnel");
    } else {
      setStatus("Bitte geben Sie eine gültige Email ein");
    }
  };

  const addWaitlist = async () => {
    let result;
    try {
      result = await axios.post(
        `${config.API_URL}/default/leads/${email}/waitlist?feature=${feature}`
      );
    } catch (error) {
      console.error(error);
    }

    if (result && result.status === 201) {
      setStatus(t("We have successfully added you to our waitlist."));
      setTimeout(onClose, 3000);
    } else {
      setStatus(t("An error has occured"));
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div style={styles}>
      <CancelButton
        style={{ position: "absolute", right: "2%", top: "2%" }}
        onClick={onClose}
      ></CancelButton>
      <p
        style={{
          margin: 0,
          marginLeft: "10%",
          marginTop: "10%",
          fontSize: "2rem",
          fontWeight: 600,
          bottom: 0,
        }}
      >
        Wir brauchen noch Ihre Email
      </p>
      <p
        style={{
          marginLeft: "10%",
          fontSize: "1.3rem",
          fontWeight: 400,
          top: 0,
        }}
      >
        {subtitle}
      </p>
      <Input
        style={{
          marginLeft: "10%",
          width: "80%",
          marginTop: "10%",
          height: "3.5rem",
        }}
        onChange={(event) => handleEmailChange(event)}
        onEnter={funnel ? createLead : addWaitlist}
        placeholder="Ihre Email"
      ></Input>
      <p style={{ marginLeft: "10%" }}>{status}</p>
      <Button
        style={{ marginLeft: "10%", width: "80%", marginTop: "2%" }}
        label={funnel ? "Mein Anliegen prüfen" : "Benachrichtigt mich!"}
        onClick={funnel ? createLead : addWaitlist}
      ></Button>
    </div>
  );
};
