import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const Terms = ({}) => {
  const { t, i18n } = useTranslation();

  useAnalytics();
  return (
    <div>
      <Header staticColor={true}></Header>
      <Container
        itemWidth="100%"
        columnWidths={["100%"]}
        style={{ width: "100%" }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "3rem",
            fontWeight: 900,
            marginTop: "25%",
          }}
        >
          {t("SMARTMIETER TERMS")}
        </h1>
        <p style={{ color: "#AAA6A6", width: "50%", marginLeft: "25%" }}>
          {t(
            "Thank you for your interest in SMARTMIETER. At SMARTMIETER, our top priority is to provide you as a customer with clear, understandable, and reliable information about rental law. Our service aims to provide you with legal guidance in a straightforward manner and support you in your individual situation. We place great importance on transparency and quality - by combining AI-based support with direct access to specialized partner lawyers, we ensure that you are always well-informed."
          )}
        </p>
        <AGB></AGB>
      </Container>
      <Footer></Footer>
    </div>
  );
};

const Sectiontitle = ({ children }) => (
  <h3 style={{ fontSize: "1.5em", margin: "1em 0", fontWeight: "bold" }}>
    {children}
  </h3>
);

const AGB = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "50%",
        marginLeft: "25%",
        marginTop: "20%",
        marginBottom: "10%",
      }}
    >
      <Sectiontitle>
        {t("1. Scope and Subject Matter of the Contract")}
      </Sectiontitle>
      <p>
        {t(
          "These Terms and Conditions (AGB) apply to all business relationships between smartmieter UG (haftungsbeschränkt) (hereinafter referred to as 'SMARTMIETER' or 'we') and its customers (hereinafter referred to as 'users' or 'you') who use our website and services. By using our website and services, you agree to these Terms and Conditions. The contract is concluded by clicking the 'Create Account' button on our payment page, by which the user accepts these Terms and Conditions."
        )}
      </p>

      <Sectiontitle>{t("2. Description of Services")}</Sectiontitle>
      <p>
        {t(
          "SMARTMIETER offers AI-based legal advice and individual consulting services through partner lawyers on its website. Our focus is on rental law. We offer one-time consultations (one-time fees) and subscriptions (monthly or annually, as indicated). SMARTMIETER points out that the accuracy and completeness of the information generated by AI cannot be guaranteed. Binding legal advice can only be provided by our partner lawyers."
        )}
      </p>

      <Sectiontitle>
        {t("3. Conclusion of Contract and Payment Terms")}
      </Sectiontitle>
      <p>
        {t(
          "The contract between SMARTMIETER and the user is concluded by clicking the 'Create Account' button on the payment page. All fees are to be paid in advance. Billing is either one-time (for individual consultations) or as a subscription (monthly or annually), depending on the chosen offer."
        )}
      </p>

      <Sectiontitle>{t("4. Use of AI-Based Consultation")}</Sectiontitle>
      <p>
        {t(
          "The AI-generated information and answers provided on our website are intended solely for orientation and informational purposes. They do not constitute legally binding advice and do not replace consultation with a qualified lawyer. SMARTMIETER assumes no liability for the accuracy or completeness of the information generated by the AI. The reliability of the AI's responses cannot be guaranteed."
        )}
      </p>

      <Sectiontitle>{t("5. Disclaimer of Liability")}</Sectiontitle>
      <p>
        {t(
          "(a) SMARTMIETER is not liable for damages resulting from the use of or reliance on the information and recommendations of our AI. Users are responsible for critically reviewing the AI-generated information and seeking legal advice if necessary."
        )}
        <br />
        {t(
          "(b) SMARTMIETER is not responsible for communication with third parties, including messages to landlords or property managers, generated based on AI recommendations. The user is solely responsible for the accuracy and effectiveness of such messages."
        )}
        <br />
        {t(
          "(c) The liability for the services provided by our partner lawyers lies solely with the respective lawyers. SMARTMIETER assumes no liability for these services."
        )}
      </p>

      <Sectiontitle>{t("6. Availability of Services")}</Sectiontitle>
      <p>
        {t(
          "SMARTMIETER strives to maintain the availability of the website and the services offered as high as possible. However, no guarantee is given for continuous availability. Downtime and maintenance may occur without prior notice, and SMARTMIETER is not liable for any damages or outages resulting from these."
        )}
      </p>

      <Sectiontitle>
        {t("7. Termination and Termination of the Contract")}
      </Sectiontitle>
      <p>
        {t(
          "Individual consultations end with the one-time provision of services by SMARTMIETER or the partner lawyer. Subscription contracts can be terminated by either party with a notice period of 14 days before the end of the respective billing period, depending on the chosen plan. Terminations can be made in writing or through the website."
        )}
      </p>

      <Sectiontitle>{t("8. Data Protection")}</Sectiontitle>
      <p>
        {t(
          "SMARTMIETER processes personal data in accordance with applicable data protection regulations. Further information on how personal data is handled can be found in our "
        )}
        <a href="/privacy" style={{ color: "black" }}>
          {t("Privacy Policy")}
        </a>
        .
      </p>

      <Sectiontitle>{t("9. Success of your case")}</Sectiontitle>
      <p>
        {t(
          "Although our core mission is to provide you with the best possible support, we cannot guarantee success in your legal matters. The outcome of legal disputes depends on various factors, including the individual case and the legal situation. SMARTMIETER is not liable for the success or failure of legal disputes."
        )}
      </p>

      <Sectiontitle>
        {t("10. Changes to the Terms and Conditions")}
      </Sectiontitle>
      <p>
        {t(
          "SMARTMIETER reserves the right to change these Terms and Conditions at any time. Changes will be communicated to users by email or through the website. Continued use of the services after the changes come into effect will be considered as acceptance of the revised terms."
        )}
      </p>

      <Sectiontitle>{t("11. Final Provisions")}</Sectiontitle>
      <p>
        {t(
          "If any provision of these Terms and Conditions is or becomes invalid, the validity of the remaining provisions shall not be affected. The invalid provision will be replaced by a regulation that comes as close as possible to the economic purpose of the original provision."
        )}
      </p>

      <p>{t("As of: December 10, 2024")}</p>
    </div>
  );
};

export default AGB;
