import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { useTranslation } from "react-i18next";
import "../../i18n";

export const How = () => {
  const { t, i18n } = useTranslation();
  useAnalytics();

  return (
    <div style={{ marginTop: "10%", height: "160vh" }}>
      <Container
        margin="0"
        itemWidth="fit-content"
        itemsPerRow={1}
        columnWidths={["100%"]}
        style={{ marginLeft: "5%", width: "100%" }}
      >
        <p
          style={{
            fontWeight: 800,
            fontSize: "3rem",
            whiteSpace: "nowrap",
            marginBottom: 0,
          }}
        >
          {t("SMARTMIETER IS THIS EASY")}
        </p>
        <p
          style={{ fontSize: "1.2rem", fontWeight: 500, width: "50%", top: 0 }}
        >
          {t("Let us assist you with all problems concering renting. ")}
          {t("We'll support you 24/7 with our digital tools and will put ")}
          {t("you in contact with our partner attorneys if it comes to it.")}
        </p>
        <Container
          margin="0"
          itemWidth="100%"
          itemsPerRow={2}
          columnWidths={["50%", "50%"]}
          style={{ marginTop: "5%", width: "100%" }}
        >
          <Container
            margin="0"
            itemWidth="100%"
            itemsPerRow={1}
            style={{ top: 0, height: "20rem" }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_reasons.png`
              )}
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: "1.5rem",
                width: "80%",
                marginTop: "2%",
                top: 0,
                marginBottom: 0,
              }}
            >
              {t(
                "No warm water, paying too much rent or high sidecharges, or even invalid rental clauses in your contract?"
              )}
            </p>
            <p style={{ fontWeight: 500, fontSize: "1.2rem", width: "80%" }}>
              {t(
                "If you are unsatisfied with your rental situation and/or are suffering from contractual or financial burdens, we can help. We'll show you what rights you have as a renter against burdensome rental situations."
              )}
            </p>
          </Container>
          <Container
            margin="0"
            itemWidth="100%"
            style={{ top: 0, height: "20rem" }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_steps.png`
              )}
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: "1.5rem",
                marginTop: "2%",
                width: "80%",
                marginBottom: 0,
              }}
            >
              {t("Receive 24/7 consultation and learn about your options.")}
            </p>
            <p style={{ fontWeight: 500, fontSize: "1.2rem", width: "80%" }}>
              {t(
                "Start asking questions about your issues and we'll see what we is possible. With smartmieter, you aren't left on hold or charged hidden and high fees, you get the help you pay for. Quick and relevant help to your situation."
              )}
            </p>
          </Container>
        </Container>

        <Container
          margin="0"
          itemWidth="100%"
          itemsPerRow={2}
          columnWidths={["50%", "50%"]}
          style={{ marginTop: "20%", width: "100%" }}
        >
          <Container
            margin="0"
            itemWidth="100%"
            itemsPerRow={1}
            style={{ top: 0, height: "20rem" }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_you_decide.png`
              )}
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: "1.5rem",
                width: "80%",
                marginTop: "2%",
                marginBottom: 0,
              }}
            >
              {t("We'll solve your rental issues for you.")}
            </p>
            <p style={{ fontWeight: 500, fontSize: "1.2rem", width: "80%" }}>
              {t(
                "After we've understood your issue, we'll build a line of communication with your landlord of management company in order to resolve your issue. If this doesn't solve the problem, then we'll refer you to our expert partner attorneys."
              )}
            </p>
          </Container>
          <Container
            margin="0"
            itemWidth="100%"
            style={{ top: 0, height: "20rem" }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_result.png`
              )}
            ></img>
            <p
              style={{
                fontWeight: 700,
                fontSize: "1.5rem",
                marginTop: "2%",
                width: "80%",
                marginBottom: 0,
              }}
            >
              {t("You receive the rights you are entitled to.")}
            </p>
            <p style={{ fontWeight: 500, fontSize: "1.2rem", width: "80%" }}>
              {t(
                "Your access to your rights shouldn't be a question of time or money. With smartmieter, we are working to ensure renters have access to their deserved rights by accompanying renters throughout their disputes. High rents, issues with your accomodation, or other issues can all be addressed by our team."
              )}
            </p>
          </Container>
        </Container>
      </Container>
    </div>
  );
};
