import { Header } from "../shared/header";
import React, { useState } from "react";
import "./chat.css";
import { useAnalytics } from "../analytics";
import { Button, Container } from "../shared/components";
import { useEffect, useRef } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { getStageConfig } from "../config/config";
import { useAuth } from "../Auth/provider";
import ReactMarkdown from "react-markdown";
import { Action } from "../Cases/action";
import { Send } from "../Cases/send";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";
import axios from "axios";
import "../i18n";

export const Chat = () => {
  const { t, i18n } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [minderungKeys, setMinderungKeys] = useState({});
  const [recipientType, setRecipientType] = useState("");
  const [acceptSuggestionSelected, setAcceptSuggestionSelected] =
    useState(false);
  const [reductionClicked, setReductionClicked] = useState(false);
  const [correspondenceVisible, setCorrespondenceVisible] = useState(false);
  const [caseId, setCaseId] = useState("");
  const [reductionReason, setReductionReason] = useState("");
  const [chatId, setChatId] = useState("");
  const [optionSelected, setOptionSelected] = useState(false);
  const ws = useRef(null);

  const { user, jwtToken } = useAuth();
  const config = getStageConfig();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const initializeWebSocket = () => {
    ws.current = new ReconnectingWebSocket(
      `${config.WEBSOCKET_URL}?token=${jwtToken}`
    );
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const question = queryParams.get("question");
  const newChat = queryParams.get("new");
  const chatIdParam = queryParams.get("chatId");

  useEffect(() => {
    initializeWebSocket();

    if (newChat) {
      createChat();
    }

    if (question) {
      setInput(question);
      sendMessage();
    }

    if (chatIdParam) {
      getMessages(chatIdParam);
    }

    ws.current.onopen = () => {
      console.log("Connected to WebSocket");
    };

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      console.log(`response: ${JSON.stringify(response)}`);
      const botMessage = {
        sender: "bot",
        reduction: response.rentReduction,
        message:
          response.message === "Internal server error" ? (
            t("An Error has occured")
          ) : (
            <ReactMarkdown children={response.message}></ReactMarkdown>
          ),
      };
      setReductionReason(response.rentReduction);
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsTyping(false);
      setMinderungKeys((prevKeys) => ({
        ...prevKeys,
        [response.message]: response.rentReduction,
      }));
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error: ", error);
    };

    ws.current.onclose = () => {
      console.log("WebSocket closed");
      setTimeout(initializeWebSocket, 5000);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [question]);

  const handleShowAcceptSuggestion = (status) => {
    setAcceptSuggestionSelected(status);
    setCorrespondenceVisible(!status);
  };

  const handleSuggestionClicked = (event) => {
    setInput(event.target.innerText);
    sendMessage();
  };

  const createChat = async () => {
    try {
      const result = await axios.post(
        `${config.API_URL}/default/chat`,
        {
          userId: user?.tenantId,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      setChatId(result.data.chatId);
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  const getMessages = async (chatId) => {
    try {
      const result = await axios.get(
        `${config.API_URL}/default/chat/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      setMessages((prevMessages) => {
        const allMessages = [...prevMessages, ...result.data];
        const uniqueMessages = Array.from(
          new Set(allMessages.map((msg) => msg.id))
        ).map((id) => allMessages.find((msg) => msg.id === id));
        return uniqueMessages;
      });
    } catch (error) {}
  };

  const sendMessage = async () => {
    console.log("sendMessage");

    if (
      ws.current &&
      ws.current.readyState === WebSocket.OPEN &&
      input.trim()
    ) {
      setIsTyping(true);
      const userMessage = { sender: "user", message: input };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      ws.current.send(
        JSON.stringify({
          action: "sendMessage",
          message: input,
          userId: user?.tenantId ?? "",
          chatId,
        })
      );

      setInput("");
    } else {
      console.error("WebSocket is not open");
    }
  };

  useAnalytics();

  const suggestionPillStyle = {
    backgroundColor: "#AAD0FF",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    borderRadius: "100px",
    fontWeight: "bold",
  };

  const buttonStyle = {
    width: "30%",
    borderRadius: "100px",
  };

  const handleShowCorrespondence = () => {
    setCorrespondenceVisible(false);
  };

  const handleReductionClick = async (reason) => {
    setReductionClicked(true);

    try {
      const result = await axios.post(
        `${config.API_URL}/default/cases`,
        {
          reason,
          status: "opened",
          tenantId: user?.tenantId,
          tenantName: user?.fullName,
          language: user?.language,
          chatId,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      console.log(`result: ${JSON.stringify(result)}`);
      setCaseId(result.data.caseId);
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  const renderMessage = (message, index) => {
    const isUser = message.sender === "user";
    const reduction = message.reduction;

    console.log(`redering message: ${message.message}`);

    return (
      <div
        key={index}
        id="message-parent"
        className={`message ${isUser ? "user-message" : "bot-message"}`}
      >
        <span id="message-allocation">{message.message}</span>
        {reduction && reduction !== "False" && message.sender !== "user" ? (
          <Button
            label={t("You likely have a case")}
            style={buttonStyle}
            onClick={() => handleReductionClick(reduction)}
          ></Button>
        ) : undefined}
      </div>
    );
  };

  const handleContinueClicked = (option) => {
    setRecipientType(option);
    setOptionSelected(true);
  };

  const handleVisibility = () => {
    setOptionSelected(false);
    setReductionClicked(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header staticColor={true}></Header>
      <h1 style={{ marginLeft: "3.5%", marginTop: "7%" }}>Chat</h1>
      <div id="parent" style={{ overflowX: "hidden", height: "80%" }}>
        {/* TODO: fix this fucking shit*/}
        <div id="wide" style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <div
              style={{
                margin: "1%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {messages.length === 0 && !chatIdParam ? (
                <div>
                  <h1
                    style={{
                      textAlign: "center",
                      marginTop: "20%",
                      fontSize: "2.5rem",
                    }}
                  >
                    smartmieter Chat
                  </h1>
                  <Container
                    style={{
                      width: "70%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    itemsPerRow={2}
                    columnWidths={["50%", "50%"]}
                    itemWidth="auto"
                  >
                    <div
                      style={suggestionPillStyle}
                      onClick={(event) => handleSuggestionClicked(event)}
                    >
                      <p>
                        {t(
                          "How often is my landlord allowed to raise my rent?"
                        )}
                      </p>
                    </div>
                    <div
                      style={suggestionPillStyle}
                      onClick={(event) => handleSuggestionClicked(event)}
                    >
                      <p>
                        {t(
                          "My water isn't warming up, what can I do about it?"
                        )}
                      </p>
                    </div>
                    <div
                      style={suggestionPillStyle}
                      onClick={(event) => handleSuggestionClicked(event)}
                    >
                      <p>{t("Is my landlord allowed to ban dogs")}</p>
                    </div>
                    <div
                      style={suggestionPillStyle}
                      onClick={(event) => handleSuggestionClicked(event)}
                    >
                      <p>
                        {t(
                          "What additional costs is my landlord allowed to charge?"
                        )}
                      </p>
                    </div>
                  </Container>
                </div>
              ) : undefined}
              {messages.map((message, index) => renderMessage(message, index))}
              {isTyping && (
                <div className="message-bubble bot message bot-message">
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>
        </div>
      </div>
      <div className="input-area" style={{ bottom: 0, marginBottom: 0 }}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={t("Write a message")}
          onKeyDown={(e) => e.key === "Enter" && sendMessage()}
        />
        <button onClick={sendMessage}>{t("Send")}</button>
      </div>

      {reductionClicked ? (
        <Action
          onCancel={() => handleVisibility()}
          onClick={(option) => handleContinueClicked(option)}
        ></Action>
      ) : undefined}
      {optionSelected ? (
        <Send
          recipientType={recipientType}
          landlordInformation={
            user ? user?.landlord || user?.propertyManagement : undefined
          }
          reason={reductionReason}
          caseId={caseId}
          onCancel={() => handleVisibility()}
        ></Send>
      ) : undefined}
    </div>
  );
};
