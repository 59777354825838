import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAnalytics } from "../analytics";

export const Cards = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useAnalytics();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectParam = queryParams.get("utm_source");

    const sources = {
      card_beta_tester: "alpha",
      card_general: "home",
      card_founding_story: "story",
    };

    if (redirectParam) {
      console.log(redirectParam);
      navigate(`/${sources[redirectParam]}`);
    }
  }, [location, navigate]);

  return <div></div>;
};
